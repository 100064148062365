.nav-txt.aui-bg-wx.dark {
    background: #191919 !important;
}
.nav-txt.aui-bg-wx.dark ul li {
    color: #fff !important;
}
.nav-txt.aui-bg-wx.dark ul li.current {
    color: #fd6974 !important;
}
.nav-txt.aui-bg-wx.dark .search {
    color: #fff !important;
}
.top-nav.aui-bg-wx.dark {
    background: #191919 !important;
}
.top-nav.aui-bg-wx.dark .am-navbar-title {
    color: #fff !important;
}
.home .aui-bg-wx.dark {
    background: #1f1f1f;
}
.home .aui-bg-wx.dark .aui-bg-wx {
    background: #1f1f1f;
}
.home .aui-bg-wx.dark .aui-bg-white {
    background: #191919 !important;
}
.home .aui-bg-wx.dark .aui-text-color-black {
    color: #fff !important;
}
.home .aui-bg-wx.dark .hot-list .aui-text-color-black {
    color: #fff !important;
}
.home .aui-bg-wx.dark .hot-list .aui-list-item-title {
    color: #fff !important;
}
.home .aui-bg-wx.dark .hot-list span.aui-text-color-black {
    color: #dbc091 !important;
}
.home .aui-bg-wx.dark .aui-text-default {
    color: #878787 !important;
}
.home .aui-bg-wx.dark .editor-recommend .am-card {
    background: transparent !important;
}
.home .aui-bg-wx.dark .editor-recommend .am-card .am-card-header-content {
    color: #ffffff !important;
}
.home .aui-bg-wx.dark .am-list-item .am-list-line .am-list-content span {
    color: #ffffff !important;
}
.home .aui-bg-wx.dark .free-list .am-list.sub-list .am-list-body .am-list-line:not(:last-child) {
    border-bottom: 1px solid #353535 !important;
}
.home .aui-bg-wx.dark .editor-recommend .am-card::before {
    border-bottom: 0px !important;
}

.classify.aui-bg-wx.dark {
    background: #1f1f1f;
}
.classify.aui-bg-wx.dark .aui-content .sort-category {
    background: #191919 !important;
}
.classify.aui-bg-wx.dark .aui-content .sort-category .current .aui-bg-white {
    background: transparent !important;
    color: #888 !important;
}
.classify.aui-bg-wx.dark .aui-content .sort-category .current .aui-text-color-pink {
    background: #fd6974 !important;
    color: #ffffff !important;
    margin-right: 0.3rem;
    border: 0px !important;
}
.classify.aui-bg-wx.dark .my-body .aui-bg-white {
    background: #191919 !important;
}
.classify.aui-bg-wx.dark .sub-list .aui-font-size-16 {
    color: #ffffff !important;
}
.classify.aui-bg-wx.dark .am-list-view-scrollview {
    background: #1f1f1f !important;
}
.classify.aui-bg-wx.dark .book-list .am-list.sub-list .am-list-body .am-list-line {
    border-bottom: 1px solid #353535 !important;
}
.classify.aui-bg-wx.dark .sort-category.total-statics {
    border-top: 1px solid #353535 !important;
}

.book-shelf.user-read-log.aui-bg-wx.dark {
    background: #1f1f1f;
}
.book-shelf.user-read-log.aui-bg-wx.dark .aui-bg-wx {
    background: transparent !important;
}
.book-shelf.user-read-log.aui-bg-wx.dark .am-list-body div:not(:last-child) .am-list-line {
    border-bottom: 0px !important;
}
.book-shelf.user-read-log.aui-bg-wx.dark
    .history-book
    .am-list.sub-list
    .am-list-body
    .am-list-line {
    border-bottom: 0px !important;
}
.book-shelf.user-read-log.aui-bg-wx.dark .sub-list {
    background: #191919 !important;
}
.book-shelf.user-read-log.aui-bg-wx.dark .sub-list .aui-font-size-16 {
    color: #ffffff !important;
}

.my.aui-bg-wx.dark {
    background: #1f1f1f;
}
.my.aui-bg-wx.dark .userinfo {
    background: #191919 !important;
    color: #888 !important;
}
.my.aui-bg-wx.dark .account-block,
.my.aui-bg-wx.dark .month-block,
.my.aui-bg-wx.dark .setting-block,
.my.aui-bg-wx.dark .setting-block .aui-list {
    background: #191919 !important;
    color: #fff !important;
}
.my.aui-bg-wx.dark .userinfo .am-list-content {
    color: #ffffff !important;
}
.my.aui-bg-wx.dark .account-block .am-card-header-content,
.my.aui-bg-wx.dark .month-block .am-card-header-content,
.my.aui-bg-wx.dark .setting-block .am-card-header-content {
    color: #fff !important;
}
.my.aui-bg-wx.dark .userinfo .am-button,
.my.aui-bg-wx.dark .account-block .am-button,
.my.aui-bg-wx.dark .month-block .am-button {
    border: 0px;
}
.my.aui-bg-wx.dark .setting-block .aui-list .aui-list-item {
    border: 0px;
}
.my.aui-bg-wx.dark .setting-block .aui-list .aui-list-item-title {
    color: #fd6974 !important;
}

.aui-pay-index.aui-bg-wx.dark {
    background: #1f1f1f;
}
.aui-pay-index.aui-bg-wx.dark .aui-bg-white {
    background: #191919 !important;
}
.aui-pay-index.aui-bg-wx.dark .my-body .userinfo .am-list-content {
    color: #fff !important;
}
.aui-pay-index.aui-bg-wx.dark .free-list .am-list .am-list-content {
    color: #fff !important;
}
.aui-pay-index.aui-bg-wx.dark .am-button {
    border: 0px !important;
}

.book-cover.aui-bg-wx.dark,
.book-cover.aui-bg-wx.dark .aui-bg-wx {
    background: #1f1f1f;
}
.book-cover.aui-bg-wx.dark,
.book-cover.aui-bg-wx.dark .aui-content.aui-bg-gray-tiny {
    background: transparent !important;
}
.book-cover.aui-bg-wx.dark,
.book-cover.aui-bg-wx.dark .aui-bg-white {
    background: #191919 !important;
}
.book-cover.aui-bg-wx.dark,
.book-cover.aui-bg-wx.dark .aui-list .aui-list-item {
    border: 0px;
}
.book-cover.aui-bg-wx.dark .send-gift .aui-grid {
    background: transparent !important;
}
.book-cover.aui-bg-wx.dark .send-gift .aui-text-color-black {
    color: #fff !important;
}
.book-cover.aui-bg-wx.dark .reviews .title-List .aui-text-bold {
    color: #fff !important;
}
.book-cover.aui-bg-wx.dark .reviews .item .am-card:not(.am-card-full) {
    border: 0px;
}
.book-cover.aui-bg-wx.dark .reviews .item {
    background: #1f1f1f !important;
}
.book-cover.aui-bg-wx.dark .reviews .item .review-content {
    color: #fff !important;
}
.book-cover.aui-bg-wx.dark .reviews .am-card-body {
    border: 0px;
}
.book-cover.aui-bg-wx.dark .free-list .am-list.sub-list .am-list-body .am-list-line {
    border: 0px !important;
}
.book-cover.aui-bg-wx.dark .free-list .title-List .aui-text-color-black {
    color: #fff !important;
}
.book-cover.aui-bg-wx.dark .free-list .am-list-item .am-list-line .am-list-content span {
    color: #ffffff !important;
}
.book-cover.aui-bg-wx.dark .more-info .title-List .aui-text-color-black {
    color: #fff !important;
}
.book-cover.aui-bg-wx.dark .book-cover .am-button {
    border: 0px !important;
}
.book-cover.aui-bg-wx.dark .chapter-list .aui-list .aui-list-item-title {
    color: #fff !important;
}
.book-cover.aui-bg-wx.dark .book-base-info {
    background: transparent !important;
}
.book-cover.aui-bg-wx.dark .book-editor {
    border: 0px !important;
}
.book-cover.aui-bg-wx.dark .book-intro {
    border: 0px !important;
    background: #191919 !important;
}
.book-cover.aui-bg-wx.dark .aui-book-intro-box {
    background: #191919 !important;
}
.book-cover.aui-bg-wx.dark .book-intro.aui-grid {
    background: #191919 !important;
}
.book-cover.aui-bg-wx.dark .book-intro.aui-grid .aui-col-xs-12:active {
    background: #191919 !important;
}
.book-cover.aui-bg-wx.dark .book-intro .aui-row {
    background: #191919 !important;
}

.catalog.aui-bg-wx.dark {
    background: #1f1f1f;
}
.catalog.aui-bg-wx.dark .aui-bg-white {
    background: #191919 !important;
}
.catalog.aui-bg-wx.dark .my-body .aui-list-item-title {
    color: #fff !important;
}

.search.aui-bg-wx.dark,
.search.aui-bg-wx.dark .aui-searchbar {
    background: #1f1f1f;
}
.search.aui-bg-wx.dark .aui-card-list {
    background: #191919 !important;
}
.search.aui-bg-wx.dark .aui-card-list .aui-text-color-black {
    color: #fff !important;
}
.search.aui-bg-wx.dark .aui-card-list .aui-list-item-inner {
    color: #fff !important;
}
.search.aui-bg-wx.dark .aui-bg-white {
    background: #191919 !important;
}
.search.aui-bg-wx.dark .sub-list .aui-font-size-16 {
    color: #ffffff !important;
}
.search.aui-bg-wx.dark .sub-list .am-list-body .am-list-line {
    border: 0px !important;
}
.search.aui-bg-wx.dark .aui-searchbar {
    background: transparent !important;
}
.search.aui-bg-wx.dark .aui-content .aui-list .aui-list-item {
    border-bottom: 1px solid #353535 !important;
}

.book-review.aui-bg-wx.dark {
    background: #1f1f1f;
}
.book-review.aui-bg-wx.dark .aui-bg-white {
    background: #191919 !important;
}
.book-review.aui-bg-wx.dark .aui-list .aui-list-item {
    border: 0px !important;
}

.welfare.aui-bg-wx.dark {
    background: #1f1f1f;
}
.welfare.aui-bg-wx.dark .aui-bg-white {
    background: #191919 !important;
}
.welfare.aui-bg-wx.dark .aui-icon-close {
    color: #fff;
}
.welfare.aui-bg-wx.dark .aui-list {
    background: #191919 !important;
}
.welfare.aui-bg-wx.dark .aui-list .aui-list-item {
    border-bottom: 1px solid #353535 !important;
}
.welfare.aui-bg-wx.dark .aui-list .aui-list-item-title {
    color: #fff !important;
}

.fensi-list.aui-bg-wx.dark {
    background: #1f1f1f;
}
.fensi-list.aui-bg-wx.dark .aui-bg-white {
    background: #191919 !important;
}
.fensi-list.aui-bg-wx.dark .aui-list .aui-list-item {
    border: 0px !important;
}

.user-read-log.aui-bg-wx.dark {
    background: #1f1f1f;
}
.user-read-log.aui-bg-wx.dark .aui-bg-white {
    background: #191919 !important;
}
.user-read-log.aui-bg-wx.dark .my-body .aui-grid {
    background: #191919 !important;
}
.user-read-log.aui-bg-wx.dark .my-body .aui-grid .aui-card-list {
    background: transparent !important;
}
.user-read-log.aui-bg-wx.dark .aui-list .aui-list-item-title {
    color: #fff !important;
}
.user-read-log.aui-bg-wx.dark .history-book .line {
    border-bottom: 1px solid #353535 !important;
}

.sign-list.aui-bg-wx.dark {
    background: #1f1f1f;
}
.sign-list.aui-bg-wx.dark .aui-bg-white {
    background: #191919 !important;
}
.sign-list.aui-bg-wx.dark .am-list-body div:not(:last-child) .am-list-line {
    border: 0px !important;
}
.sign-list.aui-bg-wx.dark .am-list-item .am-list-line .am-list-content {
    color: #fff !important;
}
.sign-list.aui-bg-wx.dark .aui-list li:not(:last-child) {
    border-bottom: 1px solid #353535 !important;
}

.aui-tab.aui-pos-fix.dark {
    background: #1f1f1f;
}
.aui-tab.aui-pos-fix.dark .aui-tab-item {
    color: #fff;
}

.setting.aui-bg-wx.dark {
    background: #1f1f1f;
}
.setting.aui-bg-wx.dark .aui-bg-white {
    background: #191919 !important;
}
.setting.aui-bg-wx.dark .aui-list {
    background: #191919 !important;
}
.setting.aui-bg-wx.dark .aui-list .aui-list-item-title {
    color: #fff !important;
}
.setting.aui-bg-wx.dark .aui-list .aui-list-item {
    border: 0px !important;
}
.setting.aui-bg-wx.dark .aui-list .aui-list-header {
    background: #1f1f1f !important;
    color: #fff !important;
}
.setting.aui-bg-wx.dark .aui-list .aui-switch:checked {
    color: #fd6974;
    border-color: #fd6974;
    background-color: #fd6974;
}
.setting.aui-bg-wx.dark .aui-list .aui-list-item {
    background: transparent !important;
}

.aui-book-read.night .am-modal-content {
    background: #191919 !important;
}
.aui-book-read.night .book-read-pay-body {
    background: #191919 !important;
}
.aui-book-read.night .swiper-slide,
.aui-book-read.night .swiper-slide .am-card {
    background: transparent !important;
}
.aui-book-read.night .book-read-pay-body-block {
    border-bottom: 1px solid #353535 !important;
}

.aui-book-read.aui-book-read-body-bg-night .am-button {
    color: #ffffff !important;
}
.aui-book-read.aui-book-read-body-bg-night .recommend-title {
    color: #ffffff;
}
.aui-book-read.aui-book-read-body-bg-night .recommend-body {
    border: 1px solid #ffffff;
    border-radius: 10px;
}

.send-gift-dialog.dark,
.send-gift-dialog.dark .aui-bg-white {
    background: #191919 !important;
}
.send-gift-dialog.dark .send-gift-dialog-body {
    background: transparent !important;
}
.send-gift-dialog.dark img {
    border: 0px;
}
.send-gift-dialog.dark .aui-bar {
    background: transparent !important;
}

.checkout.aui-bg-wx.dark .adm-form .adm-list {
    background: transparent !important;
    border: 0px !important;
}

.user-order-list.aui-bg-wx.dark {
    background: #1f1f1f;
}
.user-order-list.aui-bg-wx.dark .aui-bg-white {
    background: #191919 !important;
}
.user-order-list.aui-bg-wx.dark .my-body .aui-grid {
    background: #191919 !important;
}
.user-order-list.aui-bg-wx.dark .my-body .aui-grid .aui-card-list {
    background: transparent !important;
}
.user-order-list.aui-bg-wx.dark .aui-list .aui-list-item-title {
    color: #fff !important;
}
.user-order-list.aui-bg-wx.dark .history-book .line {
    border-bottom: 1px solid #353535 !important;
}

.user-order-list.aui-bg-wx.dark .my-body .am-list-content .aui-text-color-black,
.user-order-list.aui-bg-wx.dark .aui-list-item-text .aui-text-color-black,
.user-order-list.aui-bg-wx.dark .am-list-brief,
.user-order-list.aui-bg-wx.dark .am-flexbox-item .aui-text-color-black,
.user-read-log.aui-bg-wx.dark .aui-list-item-text .aui-text-color-black,
.user-read-log.aui-bg-wx.dark .am-list-brief .aui-text-color-black {
    color: #fff !important;
}

.user-order-list.aui-bg-wx.dark .am-flexbox.aui-bg-gray-tiny {
    background: transparent !important;
}
