
@font-face {
  font-family: 'mixs-iconfont';  /* project id 331879 */
  src: url('//at.alicdn.com/t/font_331879_ibd9i9glq1.eot');
  src: url('//at.alicdn.com/t/font_331879_ibd9i9glq1.eot?#iefix') format('embedded-opentype'),
  url('//at.alicdn.com/t/font_331879_ibd9i9glq1.woff') format('woff'),
  url('//at.alicdn.com/t/font_331879_ibd9i9glq1.ttf') format('truetype'),
  url('//at.alicdn.com/t/font_331879_ibd9i9glq1.svg#iconfont') format('svg');
}
.mixs-iconfont {
  font-family:"mixs-iconfont" !important;

}

.mixs-icon-day:before { content: "\e6b4"; }

.mixs-icon-night:before { content: "\e609"; }
.mixs-icon-flag:before{content:"\e623";}
.mixs-icon-hui:before { content: "\e67f"; }
.mixs-icon-index:before { content: "\e602"; }
.mixs-icon-home:before { content: "\e60f"; }
.mixs-icon-hot:before { content: "\e7b6"; }
.mixs-icon-stars:before { content: "\e60c\e60c\e60c\e60c\e60c"; }
.mixs-icon-stars i:before{ content: "\e60c\e60c\e60c\e60c\e60c"; }
.mixs-icon-dingyue:before { content: "\e617"; }
.mixs-icon-delete:before { content: "\e646"; }
.mixs-icon-booksort:before { content: "\e603"; }
.mixs-icon-qq:before { content: "\e852"; }
.mixs-icon-wx:before { content: "\e659"; }

.mixs-icon-alipay:before { content: "\e650"; color:#1296db}
.mixs-icon-wxpay:before { content: "\e626"; color:#1afa29}
.mixs-icon-QRpay:before { content: "\e68c"; }

.mixs-icon-avatar:before { content: "\e600"; }
.mixs-icon-key:before { content: "\e6e3"; }

.mixs-icon-qq-c:before { content: "\e611"; }
.mixs-icon-wx-c:before { content: "\e717"; }
.mixs-icon-wb-c:before { content: "\e73c"; }
.mixs-icon-bookshop:before { content: "\e601";}
