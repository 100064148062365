.m-icon-hot {
  background: url(../images/hot.png);
}
body {
  font-family: 'PingFangSC-Light', 'Microsoft YaHei', Helvetica, Arial,
    sans-serif;
}

a:link {
  color: #000;
}
a:hover {
  color: #000;
}
a:visited {
  color: #000;
}
.aui-margin-center {
  margin: 0px auto;
}
.aui-padded-0 {
  padding: 0px;
}
.aui-text-bold-0 {
  font-weight: normal;
}
.aui-text-bold {
  font-weight: bold;
}
.aui-border-color-gray {
  border: 1px solid #e7e7e7;
}
.aui-border-b-color-gray {
  border-bottom: 1px solid #e7e7e7;
}

.aui-tab-item.aui-active {
  border-color: #fd6974;
  color: #fd6974 !important;
}
.aui-tab-item.aui-border-l {
  border-left: 1px solid #e7e7e7;
}
.aui-tab-item.aui-border-r {
  border-right: 1px solid #e7e7e7;
}
.aui-bg-tran {
  background:transparent!important;
}
.aui-bg-pink {
  background-color: #fd6974;
}
.aui-bg-black-light {
  background-color: #333;
}
.aui-bg-gray-light {
  background-color: #919191;
}
.aui-bg-gray-tiny {
  background-color: #f1f1f1;
}
.aui-bg-none {
  background: none !important;
}
.aui-bg-none:before {
  content: '';
}
.aui-bg-white {
  background: #fff;
  background-color: #fff;
}
.aui-bar-light,
.aui-bar-nav.aui-bar-light .aui-iconfont,
.aui-bar-nav a {
  color: #a1a1a1;
}
.aui-text-color-black {
  color: #000 !important;
}
.aui-text-color-white,
.aui-text-color-white a:link,
.aui-text-color-white a {
  color: #ffffff;
}
.aui-position-absolute {
  position: absolute;
}
.aui-text-color-pink {
  color: #ff0000;
}
.aui-text-color-pink a:link {
  color: #ff0000;
}
.aui-text-color-pink a:hover {
  color: #ff0000;
}
.aui-text-color-pink a:visited {
  color: #ff0000;
}
.aui-text-color-pink {
  color: #ff0000;
}

.aui-text-color-light-black {
  color: #212121 !important;
}
.aui-ellipsis-3 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.aui-font-size-22 {
  font-size: 1.1rem !important;
}
.aui-font-size-24 {
  font-size: 1.2rem !important;
}
.aui-font-size-26 {
  font-size: 1.3rem !important;
}

.cur {
  cursor: pointer;
}
.ms-controller {
  visibility: hidden;
}
.aui-text-gray {
  color: #aaaaaa;
}
.aui-text-gray-light {
  color: #757575;
}
.aui-text-color-gray-light {
  color: #757575;
}
.aui-text-color-gray-light a:link {
  color: #b1b1b1;
}
.aui-text-color-gray-light a:hover {
  color: #b1b1b1;
}
.aui-text-color-gray-light a:visited {
  color: #b1b1b1;
}
.aui-text-gray-middle {
  color: #757575;
}
.aui-image-s {
  width: 10rem;
  height: 4.7rem;
}
.aui-image-s-s {
  width: 8rem;
  height: 4.2rem;
}
.aui-image-s-v {
  width: 8rem;
  height: 4.2rem;
}
.aui-image-f {
  width: 100%;
  height: 8.2rem;
}
.aui-text-align-l {
  text-align: left;
}
.aui-text-align-r {
  text-align: right;
}
.aui-text-align-c {
  text-align: center;
}
.aui-display-inline {
  display: inline;
}

.aui-label-sort {
  width: 90%;
  padding: 0.25rem 0rem;
  margin: 0rem 0rem 0.5rem 0rem;
}
.aui-list-item.aui-border-none {
  border: 0px;
}
.aui-list-item.aui-border-none:after,
.aui-list-item.aui-border-none:before {
  height: 0px;
}
.aui-list .aui-list-sort .aui-list-sort-item-more {
  display: none;
}
.aui-list .aui-box-align-start {
  -webkit-box-align: start;
  display: -webkit-box;
}
.aui-list-sort .aui-list-item-title {
  width: auto;
  font-size: 0.6rem;
}
.aui-list {
  border: none;
}
li.aui-list-sort {
  border: none;
  background: #f4f4f4;
}
.aui-list-sort .aui-grid {
  background: #f4f4f4;
}
.aui-list-sort-search.aui-list .aui-list-item-inner {
  min-height: 1.2rem;
}
.aui-font-size-10 {
  font-size: 0.4rem !important;
}

.swiper-container-banner {
  width: 100%;
  height: auto;
}
.swiper-container-banner .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.swiper-container-banner .swiper-pagination span {
  float: right;
}

.aui-btn-round {
  width: 2.1rem;
  height: 2.1rem;
  line-height: 2.1rem;
  background-color: #e0e0e0;
  border-radius: 50%;
  text-align: center;
  color: #fff;
}
.swiper-container-sort {
  width: 100%;
  height: auto;
}
.swiper-container-sort .swiper-slide {
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.aui-book-hot {
  right: 0px;
  left: auto;
  top: 0rem;
  width: 40px;
  height: 40px;
  border-radius: 0rem;
  background: url('../images/hot.png') no-repeat;
  background-size: contain;
}
.aui-book-special .aui-card-list-img {
  height: 3rem;
}

.aui-label-sort-male {
  width: 100%;
  padding: 0.5rem 0 0.5rem 0;
}

.aui-natural-booklist .aui-list-item,
.aui-editor-booklist .aui-list-item,
.aui-media-booklist .aui-list-item {
  background-color: #fff;
  background-image: none;
  background-size: none;
}
.aui-natural-booklist {
  background: none;
  background-image: none;
  background-size: none;
}

.aui-sort-list .aui-label {
  background: #fff;
}

.aui-book-read h3 {
  font-size: 1rem;
  font-weight: bold;
}
.aui-book-read .aui-book-body p {
  padding: 0.5rem 0;
  text-indent: 1.4rem;
  font-size: 100%;
  line-height: 1.75rem;
}
.aui-book-read .aui-book-body.aui-text-day p,
.aui-book-read .aui-text-day {
  color: #4e4d4c;
}
.aui-book-read .aui-book-body.aui-text-night p,
.aui-book-read .aui-text-night {
  color: #919191;
}
.aui-book-read .aui-bar-nav {
  position: fixed;
  color: #d8d8d8;
  background-color: rgba(0, 0, 0, 0.7);
}
.aui-book-read .aui-bar {
  color: #d8d8d8;
  background-color: rgba(0, 0, 0, 0.7);
}
.aui-book-read .aui-bar-footer-more {
  width: 100%;
  position: fixed;
  bottom: 60px;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
}
.aui-book-read .aui-bar-footer-more .aui-list {
  background-color: rgba(0, 0, 0, 0.6);
}
.aui-book-read .aui-bar-footer-more .aui-list .aui-list-item:active {
  background: none;
}
.aui-book-read .aui-btn.aui-btn-book-read-night {
  background-color: #c1c1c1;
}
.aui-book-read .aui-bar-footer-more .aui-list.night {
  background-color: rgba(145, 145, 145, 0.7);
}
.aui-book-read .aui-bar.night {
  background-color: rgba(145, 145, 145, 0.7);
  height: 60px;
}

.aui-book-read .aui-list .aui-list-item:active {
  background: none;
}

.aui-book-read .aui-bar-footer-more .aui-list .aui-list-item {
  padding: 0.7rem;
}
.aui-book-read
  .aui-bar-footer-more
  .aui-list
  .aui-list-item
  .aui-list-item-inner {
  padding-right: 0rem;
}
.aui-book-read .aui-bar-footer-more .aui-list .aui-list-item .aui-label {
  padding: 0.38rem 0.5rem;
  font-size: 14px;
  border-radius: 0.15rem;
  vertical-align: middle;
  line-height: inherit;
}
.aui-book-read .aui-bar-tab .aui-bar-tab-item {
  height: 60px;
  font-size: 1rem;
}
.aui-book-read .aui-bar-tab .aui-bar-tab-item .aui-iconfont:before {
  font-size: 1.2rem;
}
.aui-book-read .aui-bar-tab .aui-bar-tab-item .aui-bar-tab-label {
  font-size: 14px;
}

.aui-book-read-body-bg-eyeshield {
  background-color: #d1dcd4;
}
.aui-book-read-body-bg-parchment {
  background-color: #f0e0c5;
}
.aui-book-read-body-bg-pink {
  background-color: #dbcfdd;
}
.aui-book-read-body-bg-paper {
  background-color: #e8e6e1;
}
.aui-book-read-body-bg-night {
  background-color: #161616;
  background: url('../images/bg-night.png') repeat;
}

.aui-book-read-bg-eyeshield {
  background-color: #d1dcd4;
  color: #4b5b4d;
}
.aui-book-read-bg-parchment {
  background-color: #f0e0c5;
  color: #755113;
}
.aui-book-read-bg-pink {
  background-color: #dbcfdd;
  color: #941b87;
}
.aui-book-read-bg-paper {
  background-color: #e8e6e1;
}
.aui-book-read-bg-selected {
  border-color: #009846;
  background-image: url(../images/gou.png);
  background-repeat: no-repeat;
  background-position: right 0;
  background-size: 6px auto;
}

.aui-book-chapter-list select {
  margin: 0 0.25rem 0 0;
  padding: 0;
  border: 0;
  height: 100%;
  background: #f6f6f6;
  font-size: 14px;
  display: block;
  color: #666;
  width: 100%;
  padding: 0.25rem;
}
.aui-book-chapter-list .aui-list .aui-list-item i.aui-iconfont.mixs-icon-flag {
  font-size: 1.5rem;
  color: #fd6974;
}

.aui-book-buy p {
  font-size: 14px;
  margin: 0.3rem 0 0 0;
}

.aui-buy-chapter-preview p {
  font-size: 14px;
  margin: 0.5rem 0 0 0;
}

.aui-book-buy .aui-book-body p {
  padding: 0.5rem 0;
  text-indent: 1.4rem;
  font-size: 100%;
}
.aui-book-buy .aui-book-body.aui-text-day p,
.aui-book-buy .aui-text-day {
  color: #000;
}
.aui-book-buy .aui-book-body.aui-text-night p,
.aui-book-buy .aui-text-night {
  color: #aaa;
}
.aui-book-read .aui-grid [class*='aui-col-']:active,
.aui-book-buy .aui-grid [class*='aui-col-']:active {
  background: none;
}

.aui-line-inner-text {
  height: 1px;
  border-top: 1px solid #ddd;
  text-align: center;
}
.aui-line-inner-text span {
  position: relative;
  top: -14px;
  padding: 0 10px;
  font-size: 0.65rem;
  color: #757575;
}

.aui-book-reward {
  background: #fff;
}
.aui-book-reward .aui-btn-reward {
  width: 80%;
  margin: 0.5rem auto;
  font-size: 0.5rem;
  height: 2.6rem;
  line-height: 2.6rem;
  border: 2px solid #e51c23 !important;
}
.aui-book-reward span {
  font-size: 1.2rem;
}
.aui-book-reward .aui-col-xs-4:hover {
  background: none;
}

.aui-book-reward .aui-btn-urge {
  width: 80%;
  margin: 0.5rem auto;
  font-size: 0.5rem;
  height: 2.6rem;
  line-height: 2.6rem;
  border: 2px solid #e51c23 !important;
}
.aui-read-cnt {
  text-align: center;
  background: url(../images/bg-dot-gray.png) center center no-repeat;
}

.aui-text-nowrap {
  word-break: keep-all;
  white-space: nowrap;
}
.aui-pay-body .aui-col-xs-6 {
  padding: 0.5rem 0;
}
.aui-pay-body .aui-col-xs-6.aui-border-l {
  border-left: 1px solid #e7e7e7;
}
.aui-pay-body .aui-col-xs-6.aui-border-r {
  border-right: 1px solid #e7e7e7;
}
.aui-pay-body .aui-col-xs-6.aui-border-t {
  border-top: 1px solid #e7e7e7;
}
.aui-pay-body .aui-col-xs-6.aui-border-b {
  border-bottom: 1px solid #e7e7e7;
}
.aui-pay-body .aui-col-xs-6.aui-label-pay-selected {
  background: #ffc107;
  border: 1px solid #ff8c00;
  margin-bottom: -1px;
  color: #fff;
}

.aui-readbook-dialog .modal-body {
  padding: 0px;
}
.aui-readbook-dialog .modal-header {
}

.aui-qrcode-layer {
  width: 90px;
  height: 90px;
  position: fixed;
  top: 200px;
  right: 0;
  table-layout: fixed;
  display: block;
}
.aui-book-read .aui-book-body.aui-font-size-12 p {
  text-indent: 1.2rem;
}
.aui-book-read .aui-book-body.aui-font-size-14 p {
  text-indent: 1.4rem;
}
.aui-book-read .aui-book-body.aui-font-size-16 p {
  text-indent: 1.6rem;
}
.aui-book-read .aui-book-body.aui-font-size-18 p {
  text-indent: 1.8rem;
}
.aui-book-read .aui-book-body.aui-font-size-20 p {
  text-indent: 2rem;
}

.aui-pay-index .aui-pay-body .aui-grid {
  background: none;
}
.aui-pay-index .aui-pay-body .aui-btn-pay {
  width: 94%;
  background: #fff;
  line-height: 20px;
  height: auto;
  border: 1px solid #e1e1e1;
}
.aui-pay-index .aui-pay-body .aui-btn-pay p {
  padding: 0.5rem 0;
  font-size: 0.7rem;
}
.aui-pay-index .aui-pay-body .aui-btn-pay i {
  position: absolute;
  font-size: 1.4rem;
  right: 0px;
  top: 1px;
  margin-right: 0px;
}

/*.aui-footer-bar .aui-bar-tab-label {display:inline;}*/
.aui-footer-bar .aui-active {
  color: #e51c23;
}

.mixs-icon-stars {
  position: relative;
  display: inline-block;
  color: #ccc;
}
.mixs-icon-stars span {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  color: #ffc30c;
}

.aui-user-home .aui-list {
  background: none;
}
.aui-user-home .aui-list .aui-list-item {
  background: #fff;
}
.aui-user-home .aui-grid {
  border-top: 1px solid #f1f1f1;
}

.aui-book-review textarea {
  padding: 0.2rem;
  border-radius: 0.3rem;
  color: #a1a1a1;
}
.aui-book-review .msgcount {
}
.aui-book-review .aui-chat .aui-chat-item {
  height: auto;
  margin-bottom: 0rem;
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;
  border-top: 1px solid #f1f1f1;
}
.aui-book-review .aui-chat .aui-chat-content {
  max-width: 100%;
  background: none;
  color: #757575;
}
.aui-book-review .aui-chat .aui-chat-left .aui-chat-content {
  max-width: 100%;
  background: none;
}
.aui-book-review .aui-chat .aui-chat-right .aui-chat-content {
  max-width: 100%;
  background: none;
}
.aui-book-review .aui-chat .aui-chat-left .aui-chat-inner {
  max-width: none;
}
.aui-book-review .aui-card-list {
  margin-bottom: 50px;
  border-top: 1px solid #f1f1f1;
}
.aui-book-review .aui-chat .aui-chat-media img {
  height: 2rem;
}
.aui-book-review .aui-chat .aui-chat-name {
  padding-right: 0.5rem;
  color: #a1a1a1;
  height: 2rem;
  margin-bottom: 0rem;
}

.vote-star {
  display: inline-block;
  margin-right: 6px;
  width: 85px;
  height: 17px;
  overflow: hidden;
  vertical-align: middle;
  background: url(/assets/icon/star.gif) repeat-x 0 -17px;
}
.vote-star i {
  display: inline-block;
  height: 17px;
  background: url(/assets/icon/star.gif) repeat-x 0 0;
}

.aui-book-read .aui-icon-reward {
  background: url(/assets/icon/reward.png) no-repeat 0 0;
  background-size: 3.5rem 3.5rem;
  width: 3.5rem;
  height: 3.5rem;
}

.aui-book-read ul.rewardList {
  display: block;
  position: relative;
  overflow: hidden;
  height: 36px;
}
.aui-book-read ul.rewardList li {
  position: absolute;
  top: -999em;
  left: 0;
  display: block;
  white-space: nowrap;
  padding: 0px 0px;
}
.aui-book-read ul.aui-list.rewardList .aui-list-item-media {
  padding: 0px 5px;
}
.aui-book-read ul.aui-list.rewardList .aui-list-item {
  background-image: none;
  min-height: auto;
}

.aui-pay-qrcode .aui-list {
}
.aui-pay-qrcode {
  background: #fff;
}

.aui-book-read .aui-buy-dialog .aui-bar {
  color: #212121;
  background-color: white;
}
.aui-book-read .aui-buy-dialog .aui-bar .aui-bar-btn-item.aui-active {
  background: #e51c23;
}
.aui-book-read .aui-buy-dialog .aui-bar .aui-bar-btn-item {
  border-color: #e11a00;
  line-height: 1.2rem;
}
.aui-book-read .aui-buy-dialog .aui-bar .aui-bar-btn-item.last {
  border-top-color: transparent;
}

.aui-book-read .aui-pay-body .aui-grid {
  background: none;
}
.aui-book-read .aui-pay-body .aui-btn-pay {
  width: 94%;
  background: #fff;
  line-height: 20px;
  height: auto;
  border: 1px solid #e1e1e1;
}
.aui-book-read .aui-pay-body .aui-btn-pay p {
  padding: 0.5rem 0;
}
.aui-book-read .aui-pay-body .aui-btn-pay i {
  position: absolute;
  font-size: 1.4rem;
  right: 0px;
  top: 1px;
  margin-right: 0px;
}

.user-readhistory .aui-list-item .aui-card-list-header {
  min-height: auto;
}
.user-readhistory .aui-list-item-text {
  width: 100%;
}
.user-readhistory .aui-list .aui-list-item-arrow:before {
  transform: none;
  border: 0px;
  color: red;
}
.user-readhistory .aui-list .aui-list-item-arrow {
  padding: 0px;
}
.user-readhistory .aui-list .aui-media-list-item-inner .aui-list-item-delete {
  width: 2rem;
}
.user-readhistory .aui-list .aui-col-xs-2 {
  width: auto;
}

.aui-pay-index .aui-bar-pay-type.aui-bar {
  color: #212121;
  background-color: white;
}
.aui-pay-index .aui-bar-pay-type.aui-bar .aui-bar-btn-item.aui-active {
  background: #e51c23;
}
.aui-pay-index
  .aui-bar-pay-type.aui-bar
  .aui-bar-btn-item.aui-active
  .mixs-icon-alipay:before,
.aui-pay-index
  .aui-bar-pay-type.aui-bar
  .aui-bar-btn-item.aui-active
  .mixs-icon-wxpay:before {
  color: #fff;
}
.aui-pay-index .aui-bar-pay-type.aui-bar .aui-bar-btn-item {
  border-color: #e11a00;
  line-height: 1.2rem;
}
.aui-pay-index .aui-bar-pay-type.aui-bar .aui-bar-btn-item.last {
  border-top-color: transparent;
}
.aui-pay-index .aui-bar-pay-type span {
}

.user-readhistory .aui-list.aui-media-list:first-child {
  background: transparent;
}

.no-cover-block {
  position: absolute;
  bottom: 40px;
  width: 77%; /*width:100%;max-width:100%;*/
  text-align: center;
}
.no-cover-block .articlename {
  padding: 0 0.2rem;
  text-align: center;
  font-weight: bold;
}
.no-cover-block .author {
  width: 100%;
  text-align: center;
  font-size: 0.4rem !important;
}
