@font-face {
    font-family: 'iconfont'; /* Project id 487272 */
    src: url('//at.alicdn.com/t/font_487272_ksgnjxki8rg.woff2?t=1647179753839') format('woff2'),
        url('//at.alicdn.com/t/font_487272_ksgnjxki8rg.woff?t=1647179753839') format('woff'),
        url('//at.alicdn.com/t/font_487272_ksgnjxki8rg.ttf?t=1647179753839') format('truetype');
}
.fa {
    font-family: 'iconfont' !important;
}
.fa-user:before {
    content: '\e634';
}
.fa-book:before {
    content: '\e63f';
}
.fa-up:after {
    content: '\e659';
    margin-left: 3px;
}
.fa-down:after {
    content: '\e68b';
    margin-left: 3px;
}
.fa-prize:before {
    content: '\e639';
}
.fa-gold:before {
    content: '\e6a2';
}
.fa-time:before {
    content: '\e613';
}
.fa-dot:before {
    content: '\e80c';
}
.fa-recommend:before {
    content: '\e686';
}
.fa-update:before {
    content: '\e665';
}
.aui-bg-white {
    background-color: #fff;
}
.fa-monthy:before {
    content: '\e662';
}
.fa-card:before {
    content: '\e604';
}
.fa-readlog:before {
    content: '\e743';
}
.fa-collect:before {
    content: '\e64a';
}
.fa-readtime:before {
    content: '\e61c';
}
.fa-userid:before {
    content: '\e62c';
}
.fa-if:before {
    content: '\e704';
}
.fa-shutiao:before {
    content: '\e631';
}
.fa-search:before {
    content: '\e618';
}
.fa-laba:before {
    content: '\e605';
}
.fa-del:before {
    content: '\e62e';
}
.fa-update-r:before {
    content: '\e686';
}
.fa-selectall:before {
    content: '\e60e';
}
.fa-book-select:before {
    content: '\e63a';
}
.fa-book-selected:before {
    content: '\e61b';
}
.fa-exclamation:before {
    content: '\e762';
}
.fa-moon:before {
    content: '\e602';
}
.fa-download:before {
    content: '\e63b';
}
.fa-read:before {
    content: '\e726';
}
.fa-bookshelf:before {
    content: '\e60a';
}
.fa-refresh:before {
    content: '\e758';
}
.fa-right:before {
    content: '\e606';
}
.fa-cart:before {
    content: '\e61d';
}
.fa-female:before {
    content: '\e7b7';
}
.fa-hot:before {
    content: '\1001d';
}
.fa-save:before {
    content: '\e610;';
}

html {
    background: #ededed;
}
.aui-bg-wx {
    background: #ededed;
}
.aui-bg-yellow-light {
    background-color: #ff8c00;
}
.aui-bg-gray {
    background: #666666;
}
.aui-text-color-gray {
    color: #999;
}
.aui-text-color-blue {
    color: blue;
}

.clearfix:after {
    clear: both;
    display: block;
    visibility: hidden;
    height: 0;
    content: '\0020';
}
.w-full {
    width: 100%;
}

.w-h {
    max-width: 750px;
}
.view-more {
    min-height: auto;
}
/* */
.aui-reading-booklist .author {
    color: #777777;
}
/* */
.aui-reading-booklist .aui-list-item,
.aui-timefree .aui-list-item {
    background-color: #fff;
    background-image: none;
    background-size: none;
}
.aui-timefree p {
    height: auto;
    font-size: 0.5rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-top: 3px;
}
.aui-timefree p.money {
    text-decoration: line-through;
}
.aui-todayhot {
    position: relative;
}
.aui-todayhot .aui-card-list-footer {
    position: absolute;
    bottom: 0px;
    background-color: #777;
    opacity: 0.7;
    width: 100%;
    color: #fff;
}
.aui-image-n {
    height: 2rem;
}
.aui-romance .aui-list-item-media {
    width: 45%;
    padding-right: 0.5rem;
}
.aui-romance .aui-list .aui-list-item-inner:not(0) {
    padding-right: 0px;
}
.aui-footer img {
    width: 12.5rem;
    text-align: center;
    margin-top: 1.5rem;
    display: inline;
}
.aui-sort-recommend .aui-bar-btn-item.aui-active {
    background-color: #e51c23;
}
.aui-sort-recommend .aui-bar-btn-item {
    border-color: #e51c23;
}
.swiper-container {
    width: 100%;
    height: 100%;
}
.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}
.aui-todayhot .swiper-pagination-bullet-active {
    background-color: #e51c23;
}
.aui-todayhot .swiper-container-horizontal > .swiper-pagination-bullets {
    left: 40%;
}
.aui-font-size-26 {
    font-size: 1.4rem;
}
.aui-image-s-s,
.aui-image-s {
    height: 100%;
    max-height: 100%;
}
.finishrecommend .aui-row-padded [class*='aui-col-xs-'],
.sortrecommend .aui-row-padded [class*='aui-col-xs-'],
.romance .aui-row-padded [class*='aui-col-xs-'],
.freetime .aui-row-padded [class*='aui-col-xs-'],
.reading .aui-row-padded [class*='aui-col-xs-'],
.history-booklist .aui-row-padded [class*='aui-col-xs-'] {
    padding: 0.3rem;
}
.finishrecommend .aui-media-list .aui-list-item-inner,
.reading .aui-media-list .aui-list-item-inner {
    padding: 0rem;
}
.aui-list.sortrecommend .aui-list-item-inner p {
    overflow: visible;
}
.finishrecommend .aui-list,
.aui-list.sortrecommend,
.aui-list.sortrecommend .aui-list-item {
    background: transparent;
}
.finishrecommend .aui-list .aui-list-item:last-child {
    background: transparent;
}
.mod-block {
    border-top: 3px solid #616161;
}
.aui-hr {
    border-top: 10px solid #dddddd;
}
.aui-hr.aui-bg-default {
    border: 0px;
}
.aui-hr.aui-margin-b-15 {
    border: 0px;
}
.index-footer a {
    color: #fff;
}
.index-header {
    background-image: linear-gradient(to right, #fc0e50, #e97260);
}
.qrcode-dialog-close {
    position: absolute;
    right: 5px;
    top: 5px;
    font-size: 0.8rem;
}
/* Custom, iPhone Retina */
@media screen and (min-width: 320px) {
    .history img {
        width: 2.5rem;
        height: 2.6rem;
    }
    .qrcode-dialog {
        line-height: 22px;
        padding: 0rem 1.2rem;
    }
    .hotbook-img {
        padding-left: 0.5rem;
    }
    .logo-left {
        width: 4rem;
        max-width: 4rem;
    }
    .aui-image-f {
        height: 125px;
    }
    #user-readhistory .aui-list .aui-list-item {
        padding: 0px;
    }
}
/* Extra Small Devices, Phones */
@media screen and (min-width: 480px) {
    .history img {
        width: 2.5rem;
        padding-right: 0.5rem;
        height: 2.6rem;
    }
    .qrcode-dialog {
        line-height: 22px;
        padding: 0rem 1.5rem;
    }
    .logo-left {
        width: 100%;
        max-width: 100%;
    }
    .hotbook-img {
        padding-left: 0rem;
    }
    .aui-image-f {
        height: 125px;
    }
    /*.aui-image-s-s {height:101px;}*/
}
/* Small Devices, Tablets */
@media screen and (min-width: 768px) {
    .history img {
        width: 60px;
        height: 66px;
        padding-right: 0.5rem;
    }
    .aui-image-f {
        height: 260px;
    }
    /*.aui-image-s-s {width:167px;height:218px;}*/
}
/* Medium Devices, Desktops */
@media screen and (min-width: 992px) {
}
/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {
}
.aui-list .aui-list-item {
}
body,
footer,
header,
.aui-bar-footer-more,
.aui-pay-index,
.aui-book-sort,
.aui-book-read-body {
    max-width: 750px;
    margin: 0px auto!important;
}
li.aui-list-sort,
.aui-list-sort .aui-grid {
    background-color: #fff;
}
.aui-book-sort li.aui-list-sort,
.aui-book-sort .aui-list-sort .aui-grid {
    background: transparent;
}
.book-sort-left {
    top: 45px;
    bottom: 45px;
    position: fixed;
    overflow-y: scroll;
    overflow-x: hidden;
}
.book-sort-left .aui-list {
    background: transparent;
}
.book-sort-left .aui-list .aui-list-item {
    background: transparent;
}
.book-sort-left .aui-list .aui-list-item.active {
    background-color: #fff;
}
.book-sort-left.sticky {
    float: none;
    position: fixed;
    top: 20px;
    z-index: 6;
    left: auto;
}
.book-sort header {
    position: fixed;
}
.book-sort-right {
    float: right;
    margin-top: 45px;
}
.book-sort-right .aui-list {
    background: transparent;
    background-color: #fff;
}
.compound-warm .box {
    border-radius: 0.6rem;
    min-height: 8rem;
}
.compound-warm .box .img {
    width: 8rem;
    height: 6rem;
    background-repeat: no-repeat;
    background-image: url('http://www.17sucai.com/preview/1/2017-09-18/slot/images/compound-warm-img.png');
    background-size: 100% 100%;
    margin: -4.33333333rem auto;
}
.compound-warm .box h4 {
    text-align: center;
    line-height: 1.2rem;
    color: #323232;
    font-size: 1.4rem;
    margin: 0.2rem auto;
}
.compound-warm .box h4 span {
    margin: 0 0.2rem;
    color: #f74646;
    font-weight: bolder;
    margin: 0px;
}
.compound-warm .box .btn {
    text-align: center;
    padding: 1.2rem;
}
.compound-warm .box .btn button {
    height: 2.7rem;
    width: 11.33333333rem;
    border-radius: 0.5rem;
    font-size: 1.06666667rem;
    color: #fff;
    background-color: #ff9000;
}
.special-index .aui-content .aui-card-list .aui-card-list-header {
    display: block;
}
.special-index .aui-content {
    background-color: #f5f5f5;
    margin-bottom: 40px;
}
.special-index .aui-content .aui-card-list {
    background-color: transparent;
}
.special-index .aui-content .aui-card-list .aui-card-list-content-padded {
    background-color: #fff;
}
.special-index .aui-content .aui-card-list .aui-card-list-footer {
    background-color: #fff;
}
.special-index .aui-content .aui-card-list .aui-card-list-content img {
    max-height: 10rem;
}
.aui-book-review .aui-list textarea {
    height: 5rem;
}
.aui-book-read .next-menu .aui-btn-block.aui-btn-sm {
    background-color: #00bcd4;
    text-align: center;
    color: #fff;
    border-radius: 35px;
    font-size: 14px;
    text-indent: 0;
}
.aui-book-read .next-menu .aui-btn-block.aui-btn-sm.menu-parchment,
.aui-book-read .next-menu .aui-btn-block.aui-btn-sm.nextchapter-parchment {
    background-color: #c55b00;
}
.aui-book-read .next-menu .aui-btn-block.aui-btn-sm.menu-pink,
.aui-book-read .next-menu .aui-btn-block.aui-btn-sm.nextchapter-pink {
    background-color: #d696e1;
}
.aui-book-read .next-menu .aui-btn-block.aui-btn-sm.aui-btn-book-read-night {
    background-color: #a1a1a1;
    color: #313131;
}
.aui-book-read .aui-bar.aui-bar-tab .aui-btn-block.aui-btn-sm {
    height: 2.1rem;
    line-height: 2.1rem;
}
.aui-book-read .aui-bar.aui-bar-tab .aui-btn-block.aui-btn-sm.btn-buy {
    border-radius: 2.1rem;
}
.user-readhistory .recommend-book li {
    position: relative;
}
.user-readhistory .recommend-book i.fa-recommend {
    position: absolute;
    font-size: 1.4rem;
    top: -0.25rem;
    right: 0px;
    margin-right: 0px;
}
.user-readhistory .history-book i.fa-update {
    position: absolute;
    font-size: 1.8rem;
    top: -0.5rem;
    right: 5px;
    margin-right: 0px;
    color: #ffc107;
}
.user-readhistory .dropload-noData .aui-card-list {
    background: transparent;
}
.user-readhistory .dropload-noData .aui-card-list-header,
.user-readhistory .dropload-noData .aui-card-list-footer {
    display: inline-table;
    line-height: 1.25rem;
}
.aui-book-chapter-list .aui-list-in .aui-list-item {
}
.aui-book-read .submit-error-body .aui-list textarea {
    height: 5rem;
}
.aui-book-read .aui-qrcode-layer {
    width: 63px;
    height: 63px;
}
.aui-book-read .aui-bar {
    padding: 0.4rem 0;
}
.aui-book-read .am-button {
    border: 0px !important;
}
.aui-book-read.aui-book-read-body-bg-night .am-button {
    background-color: #919191 !important;
    color: #616161 !important;
}
/*.book-index .reading .aui-timefree {border-bottom:1px solid #e7e7e7;}
    .book-index .suspense .aui-list a li.aui-list-item{border-bottom:1px solid #e7e7e7;}
    .book-index .suspense .aui-list a:last-child li.aui-list-item{border-bottom:0px;}*/

.book-index .finishrecommend .aui-list a li.aui-list-item {
    border-bottom: 1px solid #e7e7e7;
}
.book-index .finishrecommend .aui-list a:last-child li.aui-list-item {
    border-bottom: 0px;
}
.aui-user-home .btn-pay,
.aui-user-home .btn-month {
    min-width: 3rem;
    border-radius: 1.2rem;
}
.book-search .search-result .aui-image-s-s {
    height: 4.6rem;
}
/*.book-search .search-result a li.aui-list-item {border-bottom:1px solid #e7e7e7;}
.book-search .search-result a:last-child li.aui-list-item{border-bottom:0px;}*/

.aui-binduser-dialog .aui-btn {
    width: 40%;
    max-width: 750px;
    height: 2.1rem;
    line-height: 2rem;
}
.mixs-icon-qq:before {
    font-size: 1rem;
    margin-right: 0.5rem;
    color: #5cabf4;
}
.mixs-icon-wx:before {
    font-size: 1.2rem;
    margin-right: 0.5rem;
    color: #62b900;
}
.aui-binduser-dialog {
    width: 100%;
    position: absolute;
    top: 45%;
    margin-top: -2rem;
    text-align: center;
}
.aui-binduser-dialog-close {
    position: absolute;
    width: 2em;
    height: 2em;
    right: 1em;
    top: 1em;
    color: #fff;
}
.aui-book-read .aui-bar-pay-type.aui-bar {
    color: #212121;
    background-color: white;
}
.aui-book-read .aui-bar-pay-type.aui-bar .aui-bar-btn-item.aui-active {
    background: #e51c23;
}
.aui-book-read .aui-bar-pay-type.aui-bar .aui-bar-btn-item.aui-active .mixs-icon-alipay:before,
.aui-book-read .aui-bar-pay-type.aui-bar .aui-bar-btn-item.aui-active .mixs-icon-wxpay:before {
    color: #fff;
}
.aui-book-read .aui-bar-pay-type.aui-bar .aui-bar-btn-item {
    border-color: #e11a00;
    line-height: 1.2rem;
}
.aui-book-read .aui-bar-pay-type.aui-bar .aui-bar-btn-item.last {
    border-top-color: transparent;
}
.aui-book-read .aui-bar-pay-type span {
}
.layui-m-layerbtn span[yes] {
    color: #e51c23;
}
.header-bg-ios {
    background: #ededed;
    background: #ededed !important;
}
.header-bg-andriod {
    background: #f2f2f2;
    background: #f2f2f2 !important;
    border: 0px;
    margin-top: -2px;
}
.footer-height-normal {
}
.footer-height-taller {
    padding-bottom: 1rem !important;
}

.pullLoad-footer-height-normal {
    margin-bottom: 3rem;
}
.pullLoad-footer-height-taller {
    margin-bottom: 3.25rem;
}

.book-sort {
    background-color: #fff;
}
.sort-category {
    background-color: #fff;
}
.sort-category .aui-label {
    padding: 0.2rem 0.4rem;
    background: #fff;
    font-size: 0.75rem;
}
.sort-category.total-statics {
    border-top: 1px solid #f8f8f8 !important;
    padding: 0.3rem 0px !important;
}
.sort-simple {
    position: fixed;
    width: 100%;
    top: 45px;
    background: #fff;
    text-align: center;
    height: auto;
    z-index: 9999;
}
.sort-detail .aui-label-outlined.aui-label-danger:after {
    border: 1px solid #fff;
}
.sort-content .aui-list .aui-list-item {
}
。
.book-info ul {
    margin: 0px auto;
}
.book-info ul li {
    padding: 0px 0.1rem;
    float: left;
}
.send-gift-dialog,
.send-gift {
    text-align: center;
}
.send-gift-dialog img,
.send-gift img {
    width: 2rem;
    height: 2rem;
    margin: 0px auto;
}
.send-gift-dialog .current,
.send-gift .current {
    border: 1px solid #f1f1f1;
}
.send-gift-dialog .aui-bar-btn-item,
.send-gift .aui-bar-btn-item {
    border: 0px;
}
.aui-book-read .aui-bar-footer-more {
    bottom: 92px;
}
.aui-bar-footer-more .aui-list .aui-list-item {
    padding: 1rem 0.7rem;
}
.aui-book-read.aui-book-read-body-bg-day .aui-bar {
    background-color: rgba(237, 237, 237, 1);
    color: #a1a1a1;
}
.aui-book-read.aui-book-read-body-bg-day .aui-bar a {
    color: #a1a1a1;
}
.aui-book-read.aui-book-read-body-bg-day .aui-list .aui-list-item {
    background-color: rgba(237, 237, 237, 1);
    color: #a1a1a1;
    background-transport: ;
}
.compound-warm .box {
    min-height: auto;
}
.layui-m-layerbtn span[yes] {
    color: #e51c23;
}
.aui-book-read.aui-book-read-body-bg-day .aui-bar-nav .aui-btn .aui-iconfont {
    color: #a1a1a1;
}
.aui-book-read.aui-book-read-body-bg-night .aui-list .aui-list-item {
    background-color: rgba(68, 68, 68, 1);
}
.aui-book-read.aui-book-read-body-bg-night .aui-bar {
    background-color: rgba(68, 68, 68, 1);
}
.aui-book-read.aui-book-read-body-bg-night .submit-error-body .aui-list .aui-list-item {
    background-color: rgba(237, 237, 237, 1);
    color: #a1a1a1;
}
.send-gift .aui-row .aui-col-5 img {
    width: 2rem;
    height: 2rem;
    margin: 0px auto;
}
.send-gift {
    text-align: center;
}
.send-gift-dialog img,
.send-gift img {
    width: 2rem;
    height: 2rem;
    margin: 0px auto;
}
.send-gift-dialog img {
    border: 1px solid #ffffff;
}
.send-gift-dialog .current,
.send-gift .current {
    border: 1px solid #f1f1f1;
}
.send-gift-dialog .aui-bar-btn-item,
.send-gift .aui-bar-btn-item {
    border: 0px;
}
.aui-book-read.aui-book-read-body-bg-night .send-gift-dialog .aui-bar {
    background: #ffffff;
}
.send-gift-dialog .aui-bar {
    background: #ffffff;
}
.send-gift-dialog {
    background: #ededed;
}
.send-gift-dialog .send-gift-dialog-body {
    background: #ffffff;
}

fieldset {
    border: none;
    padding: 0;
    border-top: 1px #999999 solid;
    text-align: center;
    width: 100%;
    margin: auto;
    margin-top: 0.2rem;
}
.aui-book-read.aui-book-read-body-bg-night fieldset {
    border-top: 1px solid #1e1e1e;
}
.aui-book-read.aui-book-read-body-bg-day fieldset {
    border-top: 1px solid #999999;
}
.top-bg-color {
    width: 50%;
}
.top-bg-color .mode {
    background: #f7f7f7;
}
.top-bg-color span {
    display: inline-block;
    cursor: pointer;
    width: 22px;
    height: 22px;
    border-radius: 100%;
    margin: 0 3% 0 1px;
}
.top-bg-color .aui-book-read-bg-selected {
    background: transparent;
    box-shadow: 0 0 0 1px #38bef6;
}
.top-bg-color .aui-bg-gray-light {
    background-color: #203340;
}
.fa-moon {
    line-height: 35px;
    font-size: 13px;
    text-align: center;
    font-style: normal;
    position: relative;
}
.fa-moon:before {
    position: absolute;
    top: -5px;
    left: 3px;
    color: #3b5573 !important;
}
.top-size {
    width: 40%;
    float: right;
}
.top-size i {
    font-style: normal;
    line-height: 22px;
    padding: 0px 5px;
}
.top-size span {
    float: left;
    display: inline-block;
    cursor: pointer;
    width: 22px;
    height: 22px;
    border-radius: 100%;
    margin: 0 3% 0 1px;
    background: transparent;
    box-shadow: 0 0 0 1px #999;
    font-size: 0.5rem;
    line-height: 22px;
    color: #999;
    text-align: center;
}
.aui-book-read-size-eyeshield {
    color: #fff;
    box-shadow: 0 0 0 1px #d1dcd4;
}
.aui-book-read-bg-weixin {
    background-color: #ededed;
    color: #4b5b4d;
}
.aui-book-read-body-bg-weixin {
    background-color: #ededed;
}
ul.book-count-down {
    list-style: none;
    padding: 0;
    float: right;
    text-align: center;
}
ul.book-count-down li {
    float: left;
    margin: 0px 0.1rem;
}
ul.book-count-down li span {
    font-size: 12px;
    padding: 0rem 0.05rem;
    color: #fff;
    background: #ff4400;
    width: 1rem;
    display: block;
}
ul.book-count-down li.seperator {
    font-size: 20px;
    flaot: left;
    margin-top: -0.2rem;
    vertical-align: top;
}
ul.book-count-down li p {
    color: #a7abb1;
    font-size: 14px;
}
.user-home-list i {
    font-style: normal;
    font-size: 1.2rem;
}
.user-home-list .aui-list .aui-list-item-inner {
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 0.9rem;
    padding-top: 0.5rem;
}
.user-home-list {
    border-bottom: 1px solid #f0f0f0;
}
.user-home-list .aui-col-xs-4 {
    border-left: 1px solid #f0f0f0;
    border-top: 1px solid #f0f0f0;
}
.user-home-list img {
    width: 1.2rem;
    display: inline;
}
.user-home-list .aui-list .aui-list-item-label-icon {
    border-bottom: 1px solid #fff;
}
.user-home-list .aui-list .aui-list-item .aui-list-item-arrow:before {
    top: 40%;
}
.pull-right {
    float: right;
}
.pull-left {
    float: left;
}
.aui-pay-body {
    padding: 0.3rem 0.5rem 0.3rem 0.5rem;
}
.aui-pay-index .aui-pay-body .aui-current-border {
    border: 1px solid #e51c23;
}

.aui-media-list {
    background-image: none;
}
.aui-pay-index .aui-list .aui-list-item-media {
    padding: 0px;
}
.aui-pay-index .aui-pay-body .aui-btn-pay {
    padding: 0rem;
}
.aui-text-gray {
    color: #a0a0a0;
}

.block {
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    height: 100%;
    box-sizing: border-box;
}

.aui-padded-b-25 {
    padding-bottom: 1.25rem !important;
}

.fensi-list .aui-list .aui-list-item-media .flag i {
    font-style: normal;
    position: absolute;
    text-align: center;
    top: 0rem;
    left: 0.7rem;
}
.fensi-list .aui-list .aui-list-item-media .mixs-icon-flag.flag {
    font-size: 2.2rem;
    position: relative;
}
.fensi-list .aui-list .aui-list-item-media .mixs-icon-flag.flag i {
    top: 1rem;
    left: 0.9rem;
    color: #fff;
    font-size: 0.75rem;
}
.fensi-list .aui-list-item-text span:nth-last-child(2) {
    margin-right: 0.5rem;
}
.fensi-list .aui-list .aui-list-item-text .aui-list-item-right {
    padding-top: 1rem;
}
.fensi-list .flag-color-0 {
    color: #f40404;
}
.fensi-list .flag-color-1 {
    color: #ffc107;
}
.fensi-list .flag-color-2 {
    color: #03a9f4;
}

.aui-pos-fix {
    position: fixed;
}

.book-price {
    border-radius: 1rem;
    top: 0px;
    width: 100%;
    color: #e51c23;
    border: 1px solid #e51c23;
    text-align: center; /*padding:0.25px!important;*/
    margin-top: 0.25rem;
}
.book-price-1 {
    text-decoration: line-through;
}

div.am-list-body {
    padding-top: 0px;
}
div.am-list-body::before {
    background-color: white !important;
}
div.am-list-body::after {
    background-color: white !important;
}
.am-list-body {
    border-top: none !important;
    border-bottom: none !important;
    background: none !important;
}
.pull-load {
    background: #ededed;
}

.blank {
    background-color: transparent !important;
    width: 100%;
    height: 0.5rem;
}
.blank.tiny {
    background-color: #f8f8f8;
    width: 100%;
    height: 1px;
}
.blank.tiny.dashed {
    background: linear-gradient(to right, #727171, #727171 7.5px, transparent 7.5px, transparent);
    background-size: 15px 100%;
    width: 100%;
    height: 1px;
}
.image-block {
    padding: 0.4rem 0.7rem 0.4rem 0.7rem !important;
}
.free-block .book-price {
    border: 0px;
    color: #757575;
    text-decoration: line-through;
    text-align: left;
}

.history-list {
    display: inline-table;
}
.history-list span {
    white-space: nowrap;
}
.history-list .aui-label {
    padding: 0.4rem 0.5rem;
    margin-right: 0.5rem;
}
.history-list .aui-label-outlined:after {
    border-radius: 3rem;
}
.hot-search-keyword .num {
    padding: 0.1rem 0.3rem;
    text-align: center;
    margin-top: -0.2rem;
}

.weui-page button {
    height: auto;
}

.sort-content .no-cover-block {
    width: 100%;
}

.user-readhistory .history-book i.fa-update:before {
    font-size: 1.75rem;
}
.user-readhistory .history-book i.fa-update {
    position: absolute;
    font-size: 1.8rem;
    top: -0.5rem;
    right: 5px;
    margin-right: 0px;
    color: #ffc107;
}

.chapter-list {
}
.chapter-list .mixs-icon-flag {
    height: 1rem;
    line-height: 1rem;
    top: 0.2rem;
    color: #fd6974;
}
.chapter-list .mixs-icon-flag:before {
    font-size: 1.75rem;
}

.pull-load-footer-default {
    color: lightgray !important;
}

.pull-load-footer {
    background: #fff;
}
.pull-load-footer-default {
    background: #fff;
}

.minirefresh-wrap {
    background: #fff;
}
.minirefresh-theme-default .minirefresh-downwrap {
    top: 10px;
}
.minirefresh-theme-default .minirefresh-upwrap {
    min-height: auto !important;
    padding: 5px 0 !important;
    text-align: center !important;
}
/*.minirefresh-theme-default .minirefresh-downwrap .downwrap-tips, .minirefresh-theme-default .minirefresh-upwrap .upwrap-tips{display:none;}

.minirefresh-theme-default .minirefresh-downwrap .downwrap-progress, .minirefresh-theme-default .minirefresh-upwrap .upwrap-progress{display:none!important;}*/
.minirefresh-theme-default .status-nomore .upwrap-tips {
    padding: 5px 0 !important;
}
.minirefresh-downwrap .downwrap-content {
    width: 100%;
    background-image: url(../icon/xzdot.gif);
    background-size: 3rem 3rem;
    background-repeat: no-repeat;
    background-position: center center;
}
.minirefresh-downwrap .downwrap-content .downwrap-progress {
    display: none;
}
/*.minirefresh-upwrap {width: 100%;background-image: url(../icon/xzdot.gif);background-size: 3rem 3rem;background-repeat: no-repeat;background-position: center center;}*/
.minirefresh-up-islock .minirefresh-upwrap {
    display: none;
}
.minirefresh-distance-footer-none {
    margin-bottom: 0px;
}
.minirefresh-distance-header {
    margin-top: 44px;
}
.minirefresh-distance-review-header {
    margin-top: 300px;
}
.minirefresh-distance-finance-header {
    margin-top: 89px;
}
.minirefresh-distance-signlist-footer {
    margin-bottom: 76px;
}

.am-icon-dot {
    width: 100%;
    height: 25px;
    background-image: url(../icon/xzdot.gif);
    background-size: 3.7rem 3.7rem;
    background-position: center center;
    background-repeat: no-repeat;
    padding: 10px auto;
}
.am-pull-to-refresh-down .am-pull-to-refresh-indicator {
    margin-top: -50px !important;
}
.am-pull-to-refresh-indicator {
    height: 50px !important;
}

.aui-border-radius-10 {
    border-radius: 10px !important;
}
.aui-border-radius-5 {
    border-radius: 5px !important;
}
.my-body {
    border-radius: 10px;
}
.my-body:before,
.my-body:after {
    background-color: transparent !important;
}
div.am-list-body::before,
div.am-list-body::after {
    background-color: transparent !important;
}

img.tiny {
    width: 2.8rem !important;
    height: 3.55rem !important;
}
img.little {
    width: 2rem !important;
    height: 2.55rem !important;
}
img.few {
    width: 3rem !important;
    height: 3rem !important;
    border-radius: 0.3rem;
}
img.small {
    width: 5.5rem !important;
    height: 4.2rem !important;
}
img.middle {
    width: 3.4rem !important;
    height: 3.4rem !important;
    border-radius: 0.3rem;
}
img.square {
    width: 4rem !important;
    height: 4rem !important;
}
.home .am-grid .am-flexbox .am-flexbox-item .am-grid-item-content {
    padding: 0px;
}
.home .aui-list {
    background: none;
}
.home .am-grid .am-flexbox {
    background: none;
}

.home .am-list-body div:not(:last-child) .am-list-line::after {
    background: none !important;
}
.home .swiper-slide img {
    width: 100% !important;
}
.home .am-list-item .am-list-line .am-list-content {
    padding: 0px;
}

.home .editor-recommend .am-card {
    min-height: auto !important;
    padding: 15px 0px 15px 0px;
    border: 0px !important;
    background: transparent;
}
.home .editor-recommend .am-card .am-card-header-content {
    flex: 4 1;
}
.home .editor-recommend .am-card::before {
    border: 0px !important;
    border-bottom: 1px solid #f4f4f4 !important;
    width: 160% !important;
}
.home .editor-recommend .am-card .am-card-header {
    padding: 0px 0px 0px 15px !important;
}
.home .editor-recommend .am-card .am-card-header .am-card-header-extra img {
    width: 2.8rem;
    height: 2.8rem;
}
.am-tabs-default-bar-tab-active {
}
.am-tabs-default-bar-top .am-tabs-default-bar-tab::after {
    background: transparent !important;
}
.am-tabs-default-bar-top .am-tabs-default-bar-tab::before {
    background: transparent !important;
}

.title-List {
    padding: 0.2rem 0.5rem 0 0.5rem;
}
.title-List .am-list-content {
    font-size: 0.7rem !important;
    font-weight: bold;
}
.title-List .am-list-extra {
    font-size: 0.6rem !important;
}
.title-List .am-list-content .update {
    font-size: 0.7rem !important;
}
.title-List .am-list-item {
    padding-left: 0.25rem !important;
    min-height: 28px;
}
#m-tabs-0-0 {
    font-size: 0.8rem;
    width: auto !important;
    margin-left: 1rem;
    font-weight: bold;
}
#m-tabs-0-1 {
    font-size: 0.8rem;
    width: auto !important;
    margin-left: 1rem;
    font-weight: bold;
}
#m-tabs-0-2 {
    font-size: 0.8rem;
    width: auto !important;
    margin-left: 1rem;
    font-weight: bold;
}
#m-tabs-0-3 {
    font-size: 0.8rem;
    width: auto !important;
    margin-left: 0.6rem;
    font-weight: bold;
}
.am-tabs-default-bar-tab.am-tabs-default-bar-tab-active {
    font-size: 1rem !important;
    border-bottom: 2px solid #ff4400 !important;
}
.am-tabs-default-bar-tab {
    height: 25px !important;
    line-height: 25px !important;
}
.am-tabs-default-bar-top .am-tabs-default-bar-tab,
.am-tabs-default-bar-bottom .am-tabs-default-bar-tab {
    padding: 12px 0px 12px 0px !important;
}

.home .user-read-continute img {
    width: 94px !important;
    height: 34px !important;
    margin-top: 0.15rem;
}
.home .user-read-continute .content {
    border-radius: 10px 10px 0px 0px;
    width: 100%;
    padding: 0.1rem 1rem 0.1rem 1rem;
}

.home .swiper-slide img {
    height: 80px !important;
}

.home .hot-list .aui-media-list .aui-list-item-inner {
    width: 80px !important;
    word-break: break-all;
    word-wrap: break-word;
}
.home .hot-list .aui-list-item {
    border: 0px !important;
}
.home .aui-list.hot-list .aui-list-item-title {
    height: 2.45rem !important;
}

.nav-txt {
    position: fixed;
    width: 100%;
    max-width: 750px;
    z-index: 9999;
    height: 52px;
    top: 0px !important;
}
.nav-txt .userinfo.avatar {
    width: 25px;
    height: 25px;
    border-radius: 25px;
}
.nav-txt .search {
    font-size: 0.8rem;
    height: 20px;
    line-height: 20px;
}
.nav-txt ul li {
    line-height: 34px;
    float: left;
    font-size: 1rem;
    width: auto !important;
    margin-left: 0.85rem;
    font-weight: bold;
}
.nav-txt ul li.current {
    color: #ff4400;
    font-size: 1rem !important;
    border-bottom: 2px solid #ff4400 !important;
}
.nav-txt.v1 ul li {
    font-size: 0.85rem;
    margin-left: 0.55rem;
}

.nav-txt.v1 ul li.current {
    font-size: 0.85rem !important;
}

.top-nav {
    position: fixed;
    width: 100%;
    z-index: 999;
    height: 45px;
    top: 0px !important;
}

.book-shelf {
}
.book-shelf .imshar {
    position: absolute;
    z-index: 100;
    left: 0.7rem;
    right: 0;
    top: 0.4rem;
    bottom: 0;
    height: 5.7rem;
    opacity: 0;
    margin-right: 0.7rem;
}
.book-shelf img.update {
    max-width: auto;
    width: 2rem;
    position: absolute;
    z-index: 101;
    right: 0.58rem;
    top: 0.4rem;
    background: transparent;
}
.book-shelf .aui-list .aui-list-item {
    border: 0px;
    background: none;
}
.book-shelf .btn-select {
    z-index: 102;
}
.book-shelf .fa-book-select {
    color: #ffffff;
    font-size: 0.9rem !important;
    top: 16px;
    right: 20px;
}
.book-shelf .fa-book-selected {
    color: #fa8072;
    font-size: 1.1rem !important;
    top: 16px;
    right: 20px;
}

.user-read-log .my-body {
}
.user-read-log .history-book i.fa-update:before {
    font-size: 1.75rem;
}
.user-read-log .history-book i.fa-update {
    position: absolute;
    font-size: 1.8rem;
    top: -0.5rem;
    right: 0.5rem;
    margin-right: 0px;
    color: #ffc107;
}
.user-read-log .history-book .line {
    border-bottom: 1px solid #f8f8f8;
    height: 1px;
    width: 75%;
    padding: 0px;
}
.user-read-log .aui-list .aui-list-item {
    border: 0px !important;
}

.classify .aui-list .aui-list-item-inner {
    padding: 0px;
}
.classify .aui-list .aui-list-item {
    padding-right: 0.35rem !important;
}
.classify .am-list-line::after {
    background: none !important;
}
.classify .am-list-header {
    padding-top: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 0px !important;
}
.book-shelf .am-list-line::after {
    background: none !important;
}

.am-list-view-scrollview::-webkit-scrollbar {
    width: 0 !important;
}
.am-list-view-scrollview {
    -ms-overflow-style: none;
}
.am-list-view-scrollview {
    overflow: -moz-scrollbars-none;
}

.home .free-list .line,
.classify .line,
.book-shelf .line {
    border-bottom: 1px solid #f8f8f8;
    height: 1px;
    width: 75%;
    padding: 0px;
}

.home .free-list .am-list-thumb img {
    width: 2.9rem !important;
    height: 2.9rem !important;
    border-radius: 0.3rem;
}
.home .free-list .am-list.sub-list .am-list-body .am-list-line:not(:last-child) {
    border-bottom: 1px solid #f8f8f8 !important;
}

.classify .book-list .am-list-thumb img {
    width: 2.9rem !important;
    height: 2.9rem !important;
    border-radius: 0.3rem;
}
.classify .book-list .am-list.sub-list .am-list-body .am-list-line {
    border-bottom: 1px solid #f8f8f8 !important;
}

.book-shelf .history-book .am-list-thumb img {
    width: 2.9rem !important;
    height: 3.75rem !important;
}
.book-shelf .history-book .am-list.sub-list .am-list-body .am-list-line {
    border-bottom: 1px solid #f8f8f8 !important;
}
.book-shelf .history-book .am-list-item .am-list-line .am-list-extra {
    flex-basis: auto !important;
}

.book-shelf .am-list-item img {
    width: 100% !important;
}
.am-list-item {
    background: none !important;
}

.am-list-body::after,
.am-list-body .am-list-line::after,
.am-list-body::before {
    display: none !important;
    height: 0px !important;
}

.my .userinfo .am-list-item img {
    width: 70px !important;
    height: 70px !important;
    border-radius: 50%;
}
.my .userinfo .am-list-item .am-list-brief img {
    width: 20px !important;
    height: auto !important;
    border-radius: 0px!important;
    margin-top: 0px;
}
.my .am-grid.am-grid-line {
    border: 0px !important;
}
.my .am-grid.am-grid-line .am-flexbox .am-flexbox-item:first-child {
    border-left: 0px !important;
}
.my .account .am-list-item img {
    width: 22px !important;
    height: 22px !important;
}

.my .am-card {
    background: none !important;
    min-height: auto !important;
    border: 0px !important;
}
.my .am-card.account::before {
    border: 0px !important;
    display: none;
}
.my .am-card.month::before {
    border: 0px !important;
    border-radius: 0px !important;
}
.my .account .am-grid .am-flexbox {
    background: none !important;
}

.my .module .am-grid.am-grid-line:not(.am-grid-carousel)::before {
    height: 0px;
    display: none;
}
.my .module .am-grid .am-flexbox {
    background: transparent !important;
}
.my .module .am-grid.am-grid-line .am-flexbox .am-flexbox-item:first-child::before,
.my .module .am-grid.am-grid-line:not(.am-grid-carousel)::after,
.my .module .am-grid.am-grid-line .am-flexbox:last-child::after {
    background: transparent !important;
}
.btn-small {
    height: 32px !important;
    line-height: 32px !important;
}
.btn-small:hover {
    color: white !important;
}
.btn-large {
    height: 45px !important;
    line-height: 45px !important;
}
.btn-large:hover {
    color: white !important;
}
.btn-large::before {
    display: none;
}
.btn-large.gray {
    background-color: #a1a1a1;
}
.btn-large.white {
    background-color: #fff !important;
}
.btn-middle {
    height: 36px !important;
    line-height: 36px !important;
}
.btn-middle:hover {
    color: white !important;
}
.aui-pay-index .am-list-item {padding-left: 0px!important;}
.aui-pay-index .am-list-item .am-list-line {
    padding-right: 0px !important;
}
.aui-pay-index .am-list-item .am-list-thumb:first-child {margin-left:15px;}
.aui-pay-index .am-list-item .am-list-line .am-list-content {
    padding-left: 0px !important;padding:0px;
}
.aui-pay-index .aui-pay-body .am-list-item {
    padding-left: 15px !important;
    padding-right: 15px !important;
}
.aui-pay-index .am-list-item .am-list-line .am-list-line-multiple{
    margin-left: 15px !important;
}
.aui-pay-index .userinfo .avatar .am-list-body .am-list-line {
    border-right: 1px solid #ddd !important;
}
.aui-pay-index .userinfo .am-list-item img {
    width: 45px !important;
    height: 45px !important;
    border-radius: 50%;
}
.aui-pay-index .am-list-line::after {
    background: none !important;
}
.aui-pay-index .am-list-body div:not(:last-child) .am-list-line {
    border: 0px !important;
    background: none !important;
}
.aui-pay-index .my-body .am-segment-item ,.aui-pay-index .my-body .am-segment,.checkout .am-segment-item {border-radius:0px;border:0px;}
.top-nav {
    background: #ededed !important;
}

.view-scrollview {
    position: relative;
    overflow: auto;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
}
.view-scrollview-content {
    position: absolute;
    min-width: 100%;
}

.sign-list .aui-list {
    background: transparent !important;
}
.sign-list .aui-list li:not(:last-child) {
    border-bottom: 1px solid #f8f8f8;
}
.sign-list .aui-user-self {
    background: #f1f1f1;
}
.sign-list .aui-user-self .aui-list-item .aui-media-list-item-inner {
    width: 10rem;
    text-align: center;
    margin: 0px auto;
}
.sign-list .aui-list .aui-list-item {
    padding-right: 0.5rem;
}
.sign-list .aui-img-round {
    margin: 5px auto;
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    text-align: center;
    line-height: 4rem;
    font-size: 18px;
}
.sign-list .aui-list .aui-list-item-media .flag i {
    font-style: normal;
    position: absolute;
    text-align: center;
    top: 0rem;
    left: 0;
}
.sign-list .aui-list .aui-list-item-media .mixs-icon-flag.flag {
    font-size: 1.8rem;
    position: relative;
}
.sign-list .aui-list .aui-list-item-media .mixs-icon-flag.flag i {
    top: 0.7rem;
    left: 0.7rem;
    color: #fff;
    font-size: 0.75rem;
}
.sign-list .aui-list-item-text span:nth-last-child(2) {
    margin-right: 0.5rem;
}
.sign-list .aui-list .aui-list-item-text .aui-list-item-right {
    padding-top: 1rem;
}
.sign-list .flag-color-0 {
    color: #f40404;
}
.sign-list .flag-color-1 {
    color: #ffc107;
}
.sign-list .flag-color-2 {
    color: #009688;
}

.sign-list .sign-no .am-list-item img {
    width: 50px;
    height: 50px;
    border-radius: 80px;
}
.sign-list .am-list-item .am-list-line .am-list-content {
    padding-bottom: 0px !important;
}

.detail {
    margin-top: 45px;
    margin-bottom: 45px;
}
.detail .top {
}
.detail .top .am-list-item .am-list-line .am-list-brief {
    margin-top: 0px !important;
}
.detail .body .tab-title .am-tabs-default-bar-tab.am-tabs-default-bar-tab-active {
    font-size: 0.8rem !important;
}
.detail .body .tab-title {
    border-bottom: 1px solid #f4f4f4;
    border-radius: 10px 10px 0px 0px;
    width: 100%;
    z-index: 9999;
}
.detail .body .tab-title.fix {
    position: fixed;
    top: 45px;
}
.detail .body .tab-title .am-tabs-default-bar-underline {
    height: 0px;
    display: none;
}
.detail .body .book-intro {
    line-height: 25px;
}
.detail .body .chapter-list .am-list-item .am-list-line .am-list-content {
    font-size: 15px;
}
.detail .body .chapter-content p {
    font-size: 0.65rem !important;
    line-height: 26px;
}

.detail .body .reviews .am-card:before,
.detail .body .reviews .am-card-body::before {
    border: 0px !important;
    display: none;
}
.detail .body .reviews .am-card-body {
    padding: 10px !important;
    padding-top: 0px !important;
}
.detail .body .reviews .am-card {
    background: transparent !important;
}
.detail .body .reviews .item {
    background: #f8f8f8;
    border-radius: 5px;
}
.detail .body .reviews .am-list-thumb img {
    border-radius: 25px;
    width: 25px;
    height: 25px;
}

.detail .body .author-info .am-list-thumb img {
    border-radius: 35px;
    width: 35px;
    height: 35px;
}
.detail .body .author-info .am-list-brief {
    margin-top: 0px !important;
}

.chapter-list .my-body {
}
.chapter-list .my-body .am-list-line {
}

.detail .body .user-data .book-star-section span {
    margin-left: 0.3rem;
}
.detail .body .user-data .book-star-section .star {
    margin-top: 0.1rem;
}

.aui-text-color-graywhite {
    color: #fdf9f8 !important;
}

.aui-text-color-grayblack {
    color: #d3cdcd !important;
}
.tag-black {
    background: #64605f !important;
    border-radius: 10px !important;
    color: #979392 !important;
}
.tag-black:before {
    border: 0px !important;
    border-radius: 10px !important;
}

.tag-gray {
    background: #f1f1f1 !important;
    color: #bababa !important;
    border-radius: 13px !important;
    height: auto !important;
    line-height: normal !important;
    padding: 2px 10px 2px 10px !important;
}
.tag-gray:before {
    border: 0px !important;
    border-radius: 13px !important;
}
.aui-font-size-10 {
    font-size: 0.5rem !important;
}

.book-cover .am-list-header {
    padding-bottom: 0px !important;
    padding: 0px !important;
}
.book-cover .aui-book-info .aui-list-item-title {
    color: #757575;
}
.book-cover .aui-border-l {
    border-left: 1px solid #e7e7e7;
    background-image: none;
}
.book-cover .aui-col-xs-6 {
    padding: 0rem 0rem;
}
.book-cover .aui-book-intro {
    color: #757575;
}
.book-cover .aui-book-intro-box {
    position: relative;
}
.book-cover .aui-book-intro-box .readmore-js-toggle {
    padding-left: 1rem;
    background: #fff;
    position: absolute;
    top: 40px;
    right: 0px;
}
.book-cover .aui-chat {
    background: #fff;
}
.book-cover .aui-chat .aui-chat-item {
    height: auto;
    margin-bottom: 0rem;
    padding-bottom: 0.75rem;
    padding-top: 0.75rem;
    border-top: 1px solid #f1f1f1;
}
.book-cover .aui-chat .aui-chat-content {
    max-width: 100%;
    background: none;
    color: #757575;
}
.book-cover .aui-chat .aui-chat-left .aui-chat-content {
    max-width: 100%;
    background: none;
}
.book-cover .aui-chat .aui-chat-right .aui-chat-content {
    max-width: 100%;
    background: none;
}
.book-cover .aui-chat .aui-chat-left .aui-chat-inner {
    max-width: none;
}
.book-cover .aui-card-list {
    margin-bottom: 50px;
    border-top: 1px solid #f1f1f1;
}
.book-cover .aui-chat .aui-chat-media img {
    height: 2rem;
}
.book-cover .aui-chat .aui-chat-name {
    padding-right: 0.5rem;
    color: #a1a1a1;
    height: 2rem;
    margin-bottom: 0rem;
}
.book-cover .aui-bar.aui-bar-light {
    background-image: none;
}
.book-cover .lesslink {
}
.book-cover .aui-grid {
}
.book-cover .lesslink,
.book-cover .morelink {
    width: 1rem;
    position: absolute;
    right: 0px;
    bottom: 12px;
    margin-right: 0.5rem;
    background: #fff;
}
.book-cover .fa-down:after {
}
.book-cover .chapter-list i.fa-update {
    font-size: 1.8rem;
    color: #ffc107;
}
.book-cover .aui-card-list {
    margin-bottom: 0px;
}
.book-cover .copyright-info {
    padding-top: 0.3rem;
    padding-bottom: 0.5rem;
    margin-bottom: 2.5rem;
}
.book-cover .copyright-info .aui-info {
    padding: 0.25rem 0;
}
.book-cover .author-book-list .aui-image-s-s,
.book-cover .sort-book-list .aui-image-s-s {
    height: 4.6rem;
}

.book-cover .aui-book-intro-box .aui-book-intro {
    white-space: normal !important;
}
.book-cover .line {
    background: none !important;
}
.book-cover .reviews .item {
    background: #f5f5f5 !important;
    border-radius: 10px !important;
    touch-action: pan-y;
}
.book-cover .reviews .item .am-card {
    background: transparent !important;
}
.book-cover .reviews .am-list {
    padding: 0px !important;
}
.book-cover .reviews .review-content {
    white-space: normal !important;
}
.book-cover .free-list .am-list-thumb img {
    width: 2.9rem !important;
    height: 2.9rem !important;
    border-radius: 0.3rem;
}
.book-cover .free-list .am-list.sub-list .am-list-body .am-list-line {
    border-bottom: 1px solid #f8f8f8 !important;
}
.book-cover .space-carousel {
    overflow: hidden;
    height: 170px !important;
}
.book-cover .space-carousel .slider-frame {
    height: 170px !important;
}
.book-cover .space-carousel .slider-frame .slider-list {
}
.book-cover .space-carousel .slider-frame .slider-control-centerleft {
    display: none !important;
}

.book-cover .reviews .item .am-card::before {
    border: 0px !important;
}
.book-cover .reviews .item .am-card-body {
    padding-top: 0px !important;
}
.book-cover .reviews .item .am-card-body::before {
    display: none !important;
}

.book-cover .chapter-list .fa-update:before {
    font-size: 1.8rem;
}
.book-cover .book-base-info {
    background: none !important;
    backgroundcolor: #ededed;
    border: 0px;
}
.book-cover .book-editor {
    border: 2px solid #fd6974;
}


.book-review .star span {
    margin-left: 0.5rem;
}

.aui-book-read .am-button.btn-large,
.book-cover .am-button.btn-large {
    border-radius: 10px !important;
}

.book-shelf .my-body .am-list-item .am-list-line .am-list-content {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.search .book-list .am-list-thumb img {
    width: 2.9rem !important;
    height: 2.9rem !important;
    border-radius: 0.3rem;
}
.search .book-list .am-list.sub-list .am-list-body .am-list-line {
    border-bottom: 1px solid #f8f8f8 !important;
}
.search .aui-searchbar {
    background: none;
    background-color: #ededed;
}

.aui-btn-danger {
    background-color: #fd6974 !important;
    border: 0px !important;
}
.aui-btn-danger.aui-btn-outlined {
    background-color: #fff !important;
    border: 1px solid #fd6974 !important;
}
.aui-label-outlined:after {
    display: none;
}
.am-button-warning {
    background-color: #ff0000 !important;
}
.aui-book-read .recharge-body .aui-text-color-pink.am-button-warning {
    background: #fff !important;
    color: #fd6974 !important;
}
.aui-book-read .recharge-body .am-button-warning {
    border: 1px solid #fd6974 !important;
    color: #fff !important;
}
.aui-book-read .recharge-body a.am-button-warning:hover {
    border: 1px solid #fd6974;
    color: #fd6974;
}
.aui-book-read .recharge-body a.am-button-warning:link {
    border: 1px solid #fd6974;
    color: #fd6974;
}
.aui-book-read .recharge-body .am-grid .am-flexbox .am-flexbox-item .am-grid-item-content {
    padding: 10px 0px;
}
.aui-book-read .recharge-body .am-list-body {
    border-bottom: 1px solid #f4f4f4 !important;
}
.aui-book-read .recharge-body .current .am-list-body {
    border-bottom: 1px solid #fd6974 !important;
}
.aui-book-read .recharge-body .current .am-list-item .am-list-thumb:first-child {
    margin-right: 0px;
    position: absolute;
    right: 0px;
    top: 10px;
}
.aui-book-read .recharge-body .am-list-item .am-list-line .am-list-extra {
    flex-basis: 20%;
}
.aui-book-read .am-modal-content {
    background: transparent !important;
}
.aui-book-read .book-read-pay-body {
    background: #ffffff;
    padding-bottom: 0.75rem;
    border-radius: 10px 10px 0px 0px;
}
.aui-book-read .book-read-pay-body-block {
    border-bottom: 1px solid #f4f4f4;
}
.aui-book-read .recommend-title {
    padding: 10px auto;
    color: #4e4d4c;
    font-family: SimHei;
}
.aui-book-read .recommend-body {
    padding: 10px;
    border: 1px solid #dddddd;
    border-radius: 10px;
}
.aui-book-read .recommend-body img {
}

.aui-pay-body .am-list-body {
    border: 1px solid #d4d4d4 !important;
    border-radius: 5px;
}
.aui-pay-body .current .am-list-body {
    border: 1px solid #fd6974 !important;
    border-radius: 5px;
}
.aui-pay-body .current .am-list-item .am-list-thumb:first-child {
    margin-right: 0px;
    position: absolute;
    right: 0px;
    top: 0px;
}
.aui-pay-body .am-list-item .am-list-line .am-list-extra {
    flex-basis: 20%;
}
.aui-pay-body .am-list-body .am-list-line {
    border: 0px !important;
}
.aui-pay-index .aui-pay-body .am-list-body .am-list-line {
    border: 0px !important;
}

.top-list .am-list .am-list-item .am-list-line .am-list-extra {
    flex-basis: auto !important;
}
.top-list .am-list .am-list-item .am-list-line .am-list-extra .mixs-icon-flag.flag {
    font-size: 2.2rem;
    position: relative;
    margin-top: -0.5rem;
}
.top-list .am-list .am-list-item .am-list-line .am-list-extra .mixs-icon-flag.flag i {
    font-style: normal;
    position: absolute;
    text-align: center;
    top: 0rem;
    top: 0.8rem;
    left: 0.7rem;
    color: #fff;
    font-size: 0.75rem;
}
.top-list .am-list .am-list-item .am-list-line .am-list-extra .flag-color-1 {
    color: #f40404;
}
.top-list .am-list .am-list-item .am-list-line .am-list-extra .flag-color-2 {
    color: #ffc107;
}
.top-list .am-list .am-list-item .am-list-line .am-list-extra .flag-color-3 {
    color: #03a9f4;
}

.home .title-List .am-list-item .am-list-line .am-list-arrow-horizontal {
    margin-left: 0px !important;
}
.home .title-List .am-list-item .am-list-line {
    padding-right: 0px !important;
}

.recharge-body .item {
    width: 100%;
}
.recharge-body .swiper-slide:first-child {
    margin-left: 5px;
}
.recharge-body .swiper-slide:last-child {
}
.recharge-body .space-carousel {
    overflow: hidden;
    height: 170px !important;
}
.recharge-body .item .am-card {
    height: 110px;
}
.recharge-body .item.current .am-card:before {
    border: 1px solid #ff0000;
}
.recharge-body .item .am-card-body::before {
    display: none;
}
.recharge-body .am-card-footer {
    font-size: 11px;
    background: #f40404;
    color: #fff;
    position: absolute;
    top: -10px;
    right: 10px;
    z-index: 999;
    padding: 0px 2px;
    border-radius: 2px;
}

.more-info .aui-list .aui-list-item {
    border: 0px !important;
}

.my .account-block .am-grid .am-flexbox {
    background: transparent;
}
.my .month-block .am-flexbox.am-flexbox-justify-center.am-flexbox-align-stretch {
    border-bottom: 0px !important;
}

.aui-font-size-15 {
    font-size: 0.75rem !important;
}

.activity {
    position: absolute;
    top: 58.5%;
    left: 12%;
    width: 76%;
}
.activity .am-button {
    background: transparent;
}

.setting.aui-bg-wx .aui-list .aui-list-item {
    border: 0px;
}
.setting.aui-bg-wx .aui-list .aui-list-header {
    background: #ededed !important;
    color: #000 !important;
}
.setting.aui-bg-wx .aui-list .aui-switch:checked {
    color: #fd6974;
    border-color: #fd6974;
    background-color: #fd6974;
}

html {
    width: 100%;
    height: 100%;
}
.activity-body-bg {
    width: 100%;
    height: 100%;
    background-attachment: fixed;
    background-size: cover;
    background: url(../../assets/images/activity/bg_activity_1212.jpeg);
    background-size: 100% 100%;
    background-position: center center;
    position: relative;
}
.activity .am-button {
    border: 0px;
    color: #353535 !important;
}

.checkout.home .aui-list.hot-list .aui-list-item-title {
    height: 1rem !important;
}
.checkout.home .aui-list.hot-list .am-tag-text {
    padding: 0.1rem 0.1rem;
    color: #fff;
}

.checkout.home .aui-list.hot-list .am-tag.am-tag-small {
    height: auto !important;
}

.checkout.home .adm-form .adm-form-footer {
    padding: 0px !important;
}
.checkout.home .adm-capsule-tabs-content {
    padding: 0px !important;
}
[contenteditable='true'],
input,
textarea {
    -webkit-user-select: auto !important;
    -khtml-user-select: auto !important;
    -moz-user-select: auto !important;
    -ms-user-select: auto !important;
    -o-user-select: auto !important;
    user-select: auto !important;
}
.btn-middle.btn-checkout,.btn-large.btn-checkout {
    width: 100%;
    padding: 0px !important;
}

.btn-middle.btn-contract,.btn-large.btn-contract {
    border-radius: 5px !important;
    display: block;
    outline: 0 none;
    -webkit-appearance: none;
    box-sizing: border-box;
    padding: 0;
    text-align: center;
    font-size: 18px;
    height: 47px;
    line-height: 47px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    white-space: nowrap;
}

.btn-middle.btn-checkout.blue,.btn-large.btn-contract.blue {
    color: #fff;
    background-color: #108ee9;
}

.btn-middle.btn-checkout.red,.btn-large.btn-contract.red {
    color: #fff;
    background-color: #ff0000;
}

.btn-middle.btn-checkout.yellow,.btn-large.btn-checkout.red {
    color: #fff;
    border: 0px;
    background-color: #ff0000;
}
.btn-middle.btn-checkout.yellow,.btn-large.btn-checkout.yellow {
    color: #fff;
    border: 0px;
    background-color: #ffba0d;
}

.checkout.home .adm-capsule-tabs-tab-active {
    background: #ff0000;
}
.checkout .hot-list .all-sale span {
    color: #ff0000;
}
.checkout .aui-bg-wx.dark .aui-pay-index .am-list-item .am-list-line .am-list-content {
    color: #888;
}

.checkout .aui-bg-wx.dark .hot-list span {
    color: #888;
}
.checkout .aui-bg-wx.dark .hot-list .all-sale span {
    color: #888;
}

.aui-pay-index.aui-bg-wx.dark .title-List .aui-text-color-black,
.aui-pay-index.aui-bg-wx.dark .title-List .aui-text-bold {
    color: #fff !important;
}

.aui-pay-index.aui-bg-wx.dark .aui-pay-body .am-result {
    background: transparent !important;
}

.aui-pay-index.aui-bg-wx.dark .aui-pay-body .am-result-title {
    color: #ffffff !important;
}
.home .user-order-list {
    
}
.home .user-order-list img {
    width: 24px !important;
    height: 24px !important;
    border-radius: 50%;
}
.home .user-order-list .content {
    border-radius: 0px;
    width: 100%;
    padding: 0.1rem 0.5rem 0.1rem 0.5rem;
    height: 45px !important;
    line-height: 45px !important;
}
.home .aui-bg-wx.dark .user-order-list {
    border-top: 1px solid #353535;
    border-bottom: 1px solid #353535;
}
.home .book-total .content {
    width: 100%;
    padding: 0.1rem 0.5rem 0.1rem 1.2rem;
    height: 45px !important;
    line-height: 45px !important;
}

.home .aui-bg-wx.dark .am-list-content {
    color: #fff !important;
}
.home .aui-bg-wx.dark .book-total .content {
    color: #fff !important;
}

.home .module .am-grid .am-flexbox {
    height: 105px;
}

.checkout .aui-list .aui-list-item-media img {
    border-radius: 0.3rem;
}
.user-order-list .aui-list .aui-list-item-media img,
.user-read-log .aui-list .aui-list-item-media img {
    border-radius: 0.3rem;
}

.user-reg,
.user-login {
    background: #fff;
}

.user-reg .adm-list-default,
.user-login .adm-list-default {
    border-bottom: 0px;
    border-top: 0px;
}

.user-login.aui-bg-wx.dark,
.user-login.aui-bg-wx.dark .adm-list,
.user-reg.aui-bg-wx.dark,
.user-reg.aui-bg-wx.dark .adm-list {
    background: #191919 !important;
}
.aui-bg-wx.dark .adm-input {
    color: #fff !important;
}

.classify.aui-bg-wx.dark .more-info {
    background: #191919 !important;
}
.classify.aui-bg-wx.dark .aui-bg-white {
    background: #191919 !important;
}

.classify.aui-bg-wx.dark .am-list-item .am-list-line .am-list-content .aui-text-color-black {
    color: #ffffff !important;
}
.ali-pay .more-info {
    position: relative;
}
.ali-pay .more-info .adm-auto-center {
    position: absolute;
    width: 100%;
    top: -30px;
}
.ali-pay .more-info .adm-divider-content {
    padding: 5px;
    background: #ffae5d;
    color: #fff;
    width: 150px;
    border-radius: 20px;
    text-align: center;
}
.ali-pay .more-info.aui-border-radius-10 {
    border-radius: 0px 1px 1px 1px !important;
}
.am-button::before {
    /*border: 0px !important;*/
}
.home .aui-bg-wx.dark .am-grid .am-flexbox .am-flexbox-item .am-grid-item-content span {
    color: #fff !important;
}
.nav-txt.aui-bg-wx.dark .quit {
    color: #fff !important;
}
.user-login.aui-bg-wx.dark .aui-font-size-18 {
    color: #fff !important;
}
.user-reg.aui-bg-wx.dark .aui-font-size-18 {
    color: #fff !important;
}
.nav-txt .search-input {
    width: 77px;
    padding: 0px;
    margin-top: 13px;
    background: #fff;
    border-radius: 15px;
    float: right;
    font-size: 12px;
    text-align: center;
    padding: 5px 0px;
}
.nav-txt .search-input .am-icon-xxs {
    width: 15px !important;
    height: 15px !important;
    margin-bottom: -3px;
}
.nav-txt .search-input span {
    font-size: 12px !important;
}
.nav-txt.aui-bg-wx.dark .search-input {
    background: #f7f7f7;
    color: #ff0000 !important;
}
.nav-txt .my-txt {
    position: absolute;
    border: 2px solid #ffc638;
    border-radius: 9px;
    left: -5px;
    bottom: 1px;
    background: #ff0000;
    color: white;
    font-size: 10px;
    width: 35px;
    text-align: center;
    line-height: 10px;
}
.span-radius-mini {
    border-radius: 3px;
    padding: 0px 3px 0px 3px;
}

.first-sale {
    border: 1px solid #ff0000;
    color: #ff0000;
}
.first-sale .am-tag-text {
    color: #ff0000;
}

.self_sale {
    border: 1px solid #45b97c;
    color: #45b97c;
}
.self_sale span {
    color: #45b97c;
}
.fullflag {
    border: 1px solid #ff3b6e;
    color: #ff3b6e;
}
.fullflag span {
    color: #ff3b6e;
}
.size {
    border: 1px solid #2da3ff;
    color: #2da3ff;
}
.size span {
    color: #2da3ff;
}
.sortname {
    border: 1px solid #ff0000;
    color: #ff0000;
}
.sortname span {
    color: #ff0000;
}
.sale_total {
    border: 1px solid #2da3ff;
    color: #2da3ff;
}
.sale_total span {
    color: #2da3ff;
}
.is_self {
    border: 1px solid #ff65c4;
    color: #ff65c4;
}
.is_self span {
    color: #ff65c4;
}
.home .aui-bg-wx.dark .book-list .articlename,
.home .aui-bg-wx.dark .book-list .author,
.home .aui-bg-wx.dark .book-list .intro,
.search.aui-bg-wx.dark .book-list .articlename,
.search.aui-bg-wx.dark .book-list .author,
.search.aui-bg-wx.dark .book-list .intro {
    color: #ffffff;
}
.home .aui-bg-wx.dark .aui-bg-white.image {
    background: #ffffff !important;
}
.classify.aui-bg-wx.dark .aui-bg-white.image {
    background: #ffffff !important;
}
.classify.aui-bg-wx.dark .book-list .articlename,
.classify.aui-bg-wx.dark .book-list .author,
.classify.aui-bg-wx.dark .book-list .intro {
    color: #ffffff;
}
.classify.aui-bg-wx.dark .aui-bg-white.image {
    background: #ffffff !important;
}
.user-order-list.aui-bg-wx.dark .am-list-item .am-list-line .am-list-content,
.user-read-log.aui-bg-wx.dark .am-list-item .am-list-line .am-list-content {
    color: #ffffff;
}
.user-order-list.aui-bg-wx.dark .aui-list .aui-list-item-text,
.user-read-log.aui-bg-wx.dark .aui-list .aui-list-item-text {
    color: #ffffff;
}
.title-List .am-list-item .am-list-line .am-list-content {
    padding: 0px !important;
}
.aui-pay-index .adm-input::-webkit-input-placeholder {
    color: #ff0000 !important;
    font-size: 14px;
}

.aui-pay-index .adm-input {
    font-size: 14px;
}

.sticky-footer.dark,
.sticky-footer.dark .am-flexbox {
    background: #f7f7f7 !important;
}

.sticky-footer,
.sticky-footer .am-flexbox {
    background: #ffffff !important;
}
.aui-searchbar .aui-btn {
    height: 2.2rem;
    line-height: 2.2rem;
}
.search .am-list-header {
    padding: 0px 15px 0px 15px;
}
.book-cover .title-List .aui-text-bold {
    color: #000;
    font-size: 16px;
}
.book-cover.aui-bg-wx.dark .title-List .aui-text-bold {
    color: #fff;
    font-size: 16px;
}
.book-cover {
    padding-bottom: 0.2rem;
}
.adm-page-indicator-dot-active {
    background: #ff0000 !important;
}
.line.tiny {width:100%;height:0.5px;background-color: #ededed;}
.border-pink {
    border: 1px solid #ff0000;
}
.border-gray {
    border: 0.5px solid #ededed;
}
.classify .aui-content .sort-category .current .aui-text-color-pink {
    margin-right: 0.35rem;
    margin-bottom: -0.1rem;
}
.classify .aui-content .sort-category .aui-row .aui-col-xs-3 {
    height: 47px;
}
.book-cover .am-list-body div:not(:last-child) .am-list-line,
.chapter-list .am-list-body div:not(:last-child) .am-list-line {
    border-bottom: 0.5px solid #ff0000 !important;
}

.chapter-list .am-list-body div:not(:last-child) .aui-iconfont {
    font-size: 0.9rem !important;
    color: #ff0000 !important;
}
.book-cover .chapter-content p{ text-indent: 1.2rem;color: #4e4d4c;line-height: 1.55rem;font-size:0.75rem;text-align: left;}

.book-cover .aui-list.aui-list-in .aui-list-item {background: none!important;min-height:1.7rem;}
.book-cover.aui-bg-wx.dark .aui-icon-like , .book-cover.aui-bg-wx.dark .left-title,

.book-cover.aui-bg-wx.dark .articlename,.book-cover.aui-bg-wx.dark .aui-text-dark-white,.book-cover.aui-bg-wx.dark .aui-text-dark-white p{color:#ffffff;}
.checkout .aui-list .aui-list-item-text {
    display: block;
}

.checkout .aui-list .aui-list-item-inner p {
    font-size:14px;
}

.aui-order-detail .count-down .adm-grid-item div{
line-height: 0.5rem;
padding: 1rem;
background: rgb(255, 193, 7);
text-align: center;
color: rgb(255, 255, 255);
font-size: 16px;
}
.user-order-list .count-down .adm-grid-item div {
    line-height: 1.35rem;
    text-align: center;
    color: #000000;
    border:1PX solid #ddd;
    border-radius: 5px;
    font-size: 12px;
}
.checkout .adm-button.btn-large.btn-checkout,.checkout .adm-button.btn-large.btn-checkout:hover,.checkout .adm-button.btn-large.btn-checkout:active {color:#000}
.checkout .adm-form .adm-list {background: transparent;}
.navigator-tabbar {
    max-width:750px;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    background: #fff;
    position: fixed;
    z-index: 9999;
    bottom: 0;
    width: 100%;
    color: #fff;
}
.navigator-tabbar p {color:auto}
.navigator-tabbar .am-tab-bar {width:100%;padding:0px 0px 25px 0px;font-size:14px;}
.navigator-tabbar .am-tab-bar-bar .am-tab-bar-tab-title {font-size:14px;}
.navigator-tabbar .adm-tab-bar .adm-tab-bar-item-active {color:#ff0000}

.home .free-list .aui-card-list .aui-card-list-content img {
    max-height: 100%;
}

.home .free-list .book-list .adm-swiper,.home .free-list .book-list .adm-swiper .adm-swiper-track,.home .free-list .book-list .adm-swiper .adm-swiper-track-inner,.home .free-list .book-list .adm-swiper .adm-swiper-track-inner .aui-card-list,.home .free-list .book-list .adm-swiper .adm-swiper-track-inner .aui-card-list .aui-card-list-content{height:100%}
.ms-price .am-tag {padding:0px 2px 0px 2px;border:0px;}
.ms-price .am-tag-normal::before {border:0px!important;}
.ms-price .am-tag .am-tag-text {line-height:22px;}
.text-decoration{text-decoration: line-through;}
.send-code:active,.send-code:hover{color:#007aff}
.aui-text-color-gray:active,.aui-text-color-gray:hover {color: #999;}
.margin-c {margin: 0px auto!important;}
.user-form .adm-list-body {background: transparent!important;}