/*
 * =========================================================================
 * APIClud - AUI UI 框架    流浪男  QQ：343757327  http://www.auicss.com
 * Verson 2.1
 * =========================================================================
 */
/*初始化类*/
@charset "UTF-8";
html {
    font-family: 'Helvetica Neue', Helvetica, sans-serif;
    font-size: 20px;
}
html,
body {
    -webkit-touch-callout: none;
    -webkit-text-size-adjust: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-user-select: none;
    width: 100%;
}
body {
    line-height: 1.5;
    font-size: 0.8rem;
    color: #212121;
    background-color: #f5f5f5;
    outline: 0;
}
html,
header,
section,
footer,
div,
ul,
ol,
li,
img,
a,
span,
em,
del,
legend,
center,
strong,
var,
fieldset,
form,
label,
dl,
dt,
dd,
cite,
input,
hr,
time,
mark,
code,
figcaption,
figure,
textarea,
h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    border: 0;
    padding: 0;
    font-style: normal;
}
body{
    border: 0;
    padding: 0;
    font-style: normal; 
}
* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-user-select: none;
    -webkit-tap-highlight-color: transparent;
    outline: none;
}
@media only screen and (min-width: 400px) {
    html {
        font-size: 21.33333333px !important;
    }
}
@media only screen and (min-width: 414px) {
    html {
        font-size: 21px !important;
    }
}
@media only screen and (min-width: 480px) {
    html {
        font-size: 25.6px !important;
    }
}
ul,
li {
    list-style: none;
}
p {
    font-size: 0rem;
    color: #757575;
}
a {
    color: #0062cc;
    text-decoration: none;
    background-color: transparent;
}
textarea {
    overflow: hidden;
    resize: none;
}
button {
    overflow: visible;
}
button,
select {
    text-transform: none;
}
button,
input,
select,
textarea {
    font: inherit;
    color: inherit;
}
/*自动隐藏文字*/
.aui-ellipsis-1 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.aui-ellipsis {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    word-break: break-all;
    white-space: normal !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.aui-ellipsis-2 {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    word-break: break-all;
    white-space: normal !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.aui-ellipsis-3 {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    word-break: break-all;
    white-space: normal !important;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
/*水平线*/
.aui-hr {
    width: 100%;
    position: relative;
    border-top: 1px solid #dddddd;
    height: 1px;
}
@media screen and (-webkit-min-device-pixel-ratio: 1.5) {
    .aui-hr {
        border: none;
        background-size: 100% 1px;
        background-repeat: no-repeat;
        background-position: top;
        background-image: linear-gradient(0, #dddddd, #dddddd 50%, transparent 50%);
        background-image: -webkit-linear-gradient(90deg, #dddddd, #dddddd 50%, transparent 50%);
    }
}
/*内外边距类*/
.aui-padded-0 {
    padding: 0 !important;
}
.aui-padded-5 {
    padding: 0.25rem !important;
}
.aui-padded-10 {
    padding: 0.5rem !important;
}
.aui-padded-15 {
    padding: 0.75rem !important;
}
.aui-padded-t-0 {
    padding-top: 0 !important;
}
.aui-padded-t-5 {
    padding-top: 0.25rem !important;
}
.aui-padded-t-10 {
    padding-top: 0.5rem !important;
}
.aui-padded-t-15 {
    padding-top: 0.75rem !important;
}
.aui-padded-t-20 {
    padding-top: 1rem !important;
}
.aui-padded-b-0 {
    padding-bottom: 0 !important;
}
.aui-padded-b-5 {
    padding-bottom: 0.25rem !important;
}
.aui-padded-b-10 {
    padding-bottom: 0.5rem !important;
}
.aui-padded-b-15 {
    padding-bottom: 0.75rem !important;
}
aui-padded-b-20 {
    padding-bottom: 1rem !important;
}
.aui-padded-l-0 {
    padding-left: 0 !important;
}
.aui-padded-l-5 {
    padding-left: 0.5rem !important;
}
.aui-padded-l-10 {
    padding-left: 0.6rem !important;
}
.aui-padded-l-15 {
    padding-left: 0.75rem !important;
}
.aui-padded-r-0 {
    padding-right: 0 !important;
}
.aui-padded-r-5 {
    padding-right: 0.45rem !important;
}
.aui-padded-r-10 {
    padding-right: 0.5rem !important;
}
.aui-padded-r-15 {
    padding-right: 0.75rem !important;
}
.aui-margin-0 {
    margin: 0 !important;
}
.aui-margin-5 {
    margin: 0.25rem !important;
}
.aui-margin-10 {
    margin: 0.5rem !important;
}
.aui-margin-15 {
    margin: 0.75rem !important;
}
.aui-margin-t-0 {
    margin-top: 0rem !important;
}
.aui-margin-t-5 {
    margin-top: 0.25rem !important;
}
.aui-margin-t-10 {
    margin-top: 0.5rem !important;
}
.aui-margin-t-15 {
    margin-top: 0.75rem !important;
}
.aui-margin-b-0 {
    margin-bottom: 0 !important;
}
.aui-margin-b-5 {
    margin-bottom: 0.25rem !important;
}
.aui-margin-b-10 {
    margin-bottom: 0.5rem !important;
}
.aui-margin-b-15 {
    margin-bottom: 0.5rem !important;
}
.aui-margin-l-0 {
    margin-left: 0 !important;
}
.aui-margin-l-5 {
    margin-left: 0.25rem !important;
}
.aui-margin-l-10 {
    margin-left: 0.5rem !important;
}
.aui-margin-l-15 {
    margin-left: 0.75rem !important;
}
.aui-margin-r-0 {
    margin-right: 0 !important;
}
.aui-margin-r-5 {
    margin-right: 0.25rem !important;
}
.aui-margin-r-10 {
    margin-right: 0.5rem !important;
}
.aui-margin-r-15 {
    margin-right: 0.75rem !important;
}
.aui-clearfix {
    clear: both;
}
.aui-clearfix:before {
    display: table;
    content: ' ';
}
.aui-clearfix:after {
    clear: both;
}
/*文字对齐*/
.aui-text-left {
    text-align: left !important;
}
.aui-text-center {
    text-align: center !important;
}
.aui-text-justify {
    text-align: justify !important;
}
.aui-text-right {
    text-align: right !important;
}
/*文字、背景颜色*/
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 400;
}
h1 {
    font-size: 1.2rem;
}
h2 {
    font-size: 1rem;
}
h3 {
    font-size: 0.8rem;
}
h4 {
    font-size: 0.7rem;
}
h5 {
    font-size: 0.7rem;
    font-weight: normal;
    color: #757575;
}
h6 {
    font-size: 0.7rem;
    font-weight: normal;
    color: #757575;
}
h1 small,
h2 small,
h3 small,
h4 small {
    font-weight: normal;
    line-height: 1;
    color: #757575;
}
h5 small,
h6 small {
    font-weight: normal;
    line-height: 1;
    color: #757575;
}
h1 small,
h2 small,
h3 small {
    font-size: 65%;
}
h4 small,
h5 small,
h6 small {
    font-size: 75%;
}
img {
    max-width: 100%;
    display: block;
}
.aui-font-size-12 {
    font-size: 0.6rem !important;
}
.aui-font-size-14 {
    font-size: 0.7rem !important;
}
.aui-font-size-16 {
    font-size: 0.8rem !important;
}
.aui-font-size-18 {
    font-size: 0.9rem !important;
}
.aui-font-size-20 {
    font-size: 1rem !important;
}
.aui-text-default {
    color: #212121 !important;
}
.aui-text-white {
    color: #ffffff !important;
}
.aui-text-primary {
    color: #00bcd4 !important;
}
.aui-text-success {
    color: #33cc00 !important;
    top: 4px;
}
.aui-text-info {
    color: #03a9f4 !important;
}
.aui-text-warning {
    color: #ff6600 !important;
    top: 4px;
}
.aui-text-danger {
    color: #e51c23 !important;
}
.aui-text-pink {
    color: #e91e63 !important;
}
.aui-text-purple {
    color: #673ab7 !important;
}
.aui-text-indigo {
    color: #3f51b5 !important;
}
.aui-bg-default {
    background-color: #f5f5f5 !important;
}
.aui-bg-primary {
    background-color: #00bcd4 !important;
}
.aui-bg-success {
    background-color: #009688 !important;
}
.aui-bg-info {
    background-color: #03a9f4 !important;
}
.aui-bg-warning {
    background-color: #ff6600 !important;
}
.aui-bg-danger {
    background-color: #e51c23 !important;
}
.aui-bg-pink {
    background-color: #e91e63 !important;
}
.aui-bg-purple {
    background-color: #673ab7 !important;
}
.aui-bg-indigo {
    background-color: #3f51b5 !important;
}
/*警告、成功颜色*/
.aui-warning,
.aui-warning label,
.aui-warning .aui-input,
.aui-warning .aui-iconfont {
    color: #e51c23 !important;
}
.aui-success,
.aui-success label,
.aui-success .aui-input,
.aui-success .aui-iconfont {
    color: #009688 !important;
}
/*对齐,显示，隐藏等*/
.aui-pull-right {
    float: right !important;
}
.aui-pull-left {
    float: left !important;
}
.aui-hide {
    display: none !important;
}
.aui-show {
    display: block !important;
}
.aui-invisible {
    visibility: hidden;
}
.aui-inline {
    display: inline-block;
    vertical-align: top;
}
.aui-mask {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
    opacity: 0;
    z-index: 8;
    visibility: hidden;
    -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
    transition: opacity 0.3s, transform 0.3s;
}
.aui-mask.aui-mask-in {
    visibility: visible;
    opacity: 1;
}
.aui-mask.aui-mask-out {
    opacity: 0;
}
img.aui-img-round {
    border-radius: 50%;
}
/*基本容器*/
.aui-content {
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
    word-break: break-all;
}
.aui-content-padded {
    margin: 0.75rem;
    position: relative;
    word-break: break-all;
    -webkit-overflow-scrolling: touch;
}
/*栅格类*/
.aui-row {
    overflow: hidden;
    margin: 0;
}
.aui-row-padded {
    margin-left: -0.125rem;
    margin-right: -0.125rem;
}
.aui-row-padded [class*='aui-col-xs-'] {
    padding: 0.125rem;
}
.aui-col-xs-1,
.aui-col-xs-2,
.aui-col-xs-3,
.aui-col-xs-4,
.aui-col-xs-5,
.aui-col-xs-6,
.aui-col-xs-7,
.aui-col-xs-8,
.aui-col-xs-9,
.aui-col-xs-10,
.aui-col-xs-11,
.aui-col-5 {
    position: relative;
    float: left;
}
.aui-col-xs-12 {
    width: 100%;
    position: relative;
}
.aui-col-xs-11 {
    width: 91.66666667%;
}
.aui-col-xs-10 {
    width: 83.33333333%;
}
.aui-col-xs-9 {
    width: 75%;
}
.aui-col-xs-8 {
    width: 66.66666667%;
}
.aui-col-xs-7 {
    width: 58.33333333%;
}
.aui-col-xs-6 {
    width: 50%;
}
.aui-col-xs-5 {
    width: 41.66666667%;
}
.aui-col-xs-4 {
    width: 33.33333333%;
}
.aui-col-xs-3 {
    width: 25%;
}
.aui-col-xs-2 {
    width: 16.66666667%;
}
.aui-col-xs-1 {
    width: 8.33333333%;
}
.aui-col-5 {
    width: 20%;
}
/*标签*/
.aui-label {
    display: inline-block;
    padding: 0.1em 0.2em 0.1em;
    font-size: 0.6rem;
    line-height: 1;
    color: #616161;
    background-color: #dddddd;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    border-radius: 0.15em;
    position: relative;
}

.aui-label-primary {
    color: #ffffff;
    background-color: #00bcd4;
}
.aui-label-success {
    color: #ffffff;
    background-color: #009688;
}
.aui-label-info {
    color: #ffffff;
    background-color: #03a9f4;
}
.aui-label-warning {
    color: #ffffff;
    background-color: #ffc107;
}
.aui-label-danger {
    color: #ffffff;
    background-color: #e51c23;
}
.aui-label-outlined {
    background-color: transparent;
    position: relative;
}
.aui-label-outlined:after {
    -webkit-border-radius: 2px;
    border-radius: 2px;
    height: 200%;
    content: '';
    width: 200%;
    border: 1px solid #d9d9d9;
    position: absolute;
    top: -1px;
    left: -1px;
    transform: scale(0.5);
    -webkit-transform: scale(0.5);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    z-index: 1;
}
.aui-label-outlined.aui-label-primary,
.aui-label-outlined.aui-label-primary:after {
    color: #00bcd4;
    border-color: #00bcd4;
}
.aui-label-outlined.aui-label-success,
.aui-label-outlined.aui-label-success:after {
    color: #009688;
    border-color: #009688;
}
.aui-label-outlined.aui-label-info,
.aui-label-outlined.aui-label-info:after {
    color: #03a9f4;
    border-color: #03a9f4;
}
.aui-label-outlined.aui-label-warning,
.aui-label-outlined.aui-label-warning:after {
    color: #ff6600;
    border-color: #ff6600;
}
.aui-label-outlined.aui-label-danger,
.aui-label-outlined.aui-label-danger:after {
    color: #e51c23;
    border-color: #e51c23;
}
.aui-label .aui-iconfont {
    font-size: 0.6rem;
}
/*角标*/
.aui-badge {
    display: inline-block;
    width: auto;
    text-align: center;
    min-width: 0.8rem;
    height: 0.8rem;
    line-height: 0.8rem;
    padding: 0 0.2rem;
    font-size: 0.6rem;
    color: #ffffff;
    background-color: #ff2600;
    border-radius: 0.4rem;
    position: absolute;
    top: 0.2rem;
    left: 60%;
    z-index: 99;
}
.aui-dot {
    display: inline-block;
    width: 0.4rem;
    height: 0.4rem;
    background: #ff2600;
    border-radius: 0.5rem;
    position: absolute;
    top: 0.3rem;
    right: 20%;
    z-index: 99;
}
/*按钮样式*/
button,
.aui-btn {
    position: relative;
    display: inline-block;
    font-size: 0.7rem;
    font-weight: 400;
    font-family: inherit;
    text-decoration: none;
    text-align: center;
    margin: 0;
    background: #dddddd;
    padding: 0 0.6rem;
    height: 1.5rem;
    line-height: 1.5rem;
    border-radius: 0.2rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align: middle;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-user-select: none;
    user-select: none;
}
.aui-btn-primary {
    color: #ffffff;
    background-color: #00bcd4;
}
.aui-btn-primary.aui-active,
.aui-btn-primary:active {
    color: #ffffff;
    background-color: #00acc1;
}
.aui-btn-success {
    color: #ffffff;
    background-color: #009688;
}
.aui-btn-success.aui-active,
.aui-btn-success:active {
    color: #fff;
    background-color: #00897b;
}
.aui-btn-info {
    color: #ffffff !important;
    background-color: #03a9f4 !important;
}
.aui-btn-info.aui-active,
.aui-btn-info:active {
    color: #fff !important;
    background-color: #039be5 !important;
}
.aui-btn-warning {
    color: #ffffff !important;
    background-color: #ffc107 !important;
}
.aui-btn-warning.aui-active,
.aui-btn-warning:active {
    color: #ffffff !important;
    background-color: #ffb300 !important;
}
.aui-btn-danger {
    color: #ffffff !important;
    background-color: #e51c23 !important;
}
.aui-btn-danger.aui-active,
.aui-btn-danger:active {
    color: #ffffff !important;
    background-color: #dd191b !important;
}
.aui-btn-block {
    display: block;
    width: 100%;
    height: 2.5rem;
    line-height: 2.55rem;
    margin-bottom: 0;
    font-size: 0.9rem;
}
.aui-btn-block.aui-btn-sm {
    font-size: 0.7rem;
    height: 1.8rem;
    line-height: 1.85rem;
}
.aui-btn .aui-iconfont,
.aui-btn-block .aui-iconfont {
    margin-right: 0.3rem;
}
.aui-btn .aui-badge,
.aui-btn-block .aui-badge {
    margin-left: 0.3rem;
}
.aui-btn-outlined {
    background: transparent !important;
    border: 1px solid #bdbdbd;
}
.aui-btn-outlined:active {
    background: transparent !important;
}
.aui-btn-default.aui-btn-outlined {
    color: #bdc3c7 !important;
    border: 1px solid #dcdcdc !important;
}
.aui-btn-primary.aui-btn-outlined {
    color: #00bcd4 !important;
    border: 1px solid #00bcd4 !important;
}
.aui-btn-success.aui-btn-outlined {
    color: #009688 !important;
    border: 1px solid #009688 !important;
}
.aui-btn-info.aui-btn-outlined {
    color: #03a9f4 !important;
    border: 1px solid #03a9f4 !important;
}
.aui-btn-warning.aui-btn-outlined {
    color: #ffc107 !important;
    border: 1px solid #ffc107 !important;
}
.aui-btn-danger.aui-btn-outlined {
    color: #e51c23 !important;
    border: 1px solid #e51c23 !important;
}
/*
*表单类\输入框\radio\checkbox
**/
.aui-input,
input[type='text'],
input[type='password'],
input[type='search'],
input[type='email'],
input[type='tel'],
input[type='url'],
input[type='date'],
input[type='datetime-local'],
input[type='time'],
input[type='number'],
select,
textarea {
    border: none;
    background-color: transparent;
    border-radius: 0;
    box-shadow: none;
    display: block;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 2.2rem;
    line-height: normal;
    color: #424242;
    font-size: 0.8rem;
    font-family: inherit;
    box-sizing: border-box;
    -webkit-user-select: text;
    user-select: text;
    -webkit-appearance: none;
    appearance: none;
}
input[type='search']::-webkit-search-cancel-button {
    display: none;
}
.aui-scroll-x {
    position: relative;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}
.aui-scroll-y {
    position: relative;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}
::-webkit-scrollbar {
    width: 0px;
}
/*列表*/
.aui-list {
    position: relative;
    font-size: 0.8rem;
    background-color: #ffffff;
    border-top: 1px solid #dddddd;
}
.aui-list .aui-content {
    overflow: hidden;
}
.aui-list.aui-list-noborder,
.aui-list.aui-list-noborder {
    border-top: none;
}
.aui-list .aui-list-header {
    background-color: #dddddd;
    color: #212121;
    position: relative;
    font-size: 0.6rem;
    padding: 0.4rem 0.75rem;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
}
.aui-list .aui-list-item {
    list-style: none;
    margin: 0;
    padding: 0;
    padding-left: 0.75rem;
    color: #212121;
    border-bottom: 1px solid #dddddd;
    position: relative;
    min-height: 2.2rem;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
}
.aui-list.aui-list-noborder .aui-list-item:last-child {
    border-bottom: 0;
}
.aui-list .aui-list-item-inner {
    position: relative;
    min-height: 1rem;
    padding-right: 0.75rem;
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-flex: 1;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.aui-list .aui-list-item:active {
    background-color: #f5f5f5;
}
.aui-list .aui-list-item-text {
    font-size: 0.6rem;
    color: #757575;
    position: relative;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: center;
    align-items: center;
}
.aui-list .aui-list-item-title {
    font-size: 0.7rem;
    position: relative;
    max-width: 100%;
    color: #212121;
}
.aui-list .aui-list-item-right,
.aui-list-item-title-row em {
    max-width: 50%;
    position: relative;
    font-size: 0.6rem;
    color: #757575;
    margin-left: 0.25rem;
}
.aui-list .aui-list-item-inner p {
    overflow: hidden;
}
.aui-list .aui-list-media-list {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    flex-direction: column;
}
.aui-media-list-item-inner {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
}
.aui-media-list .aui-list-item {
    display: block;
}
.aui-media-list .aui-list-item-inner {
    display: block;
    padding-top: 0.3rem;
    padding-bottom: 0.5rem;
}
.aui-media-list .aui-list-item-inners {
    display: block;
    padding-top: 0.95rem;
    padding-bottom: 0.5rem;
}
.aui-media-list-item-inner + .aui-info {
    margin-right: 0.75rem;
}
.aui-list .aui-list-item-media {
    width: 4.5rem;
    position: relative;
    padding: 0.5rem 0;
    padding-right: 0.75rem;
    display: inherit;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: flex-start;
    align-items: flex-start;
}
.aui-list .aui-list-item-media img {
    width: 100%;
    display: block;
}
.aui-list .aui-list-item-media-list {
    margin-top: 0.25rem;
    padding-right: 0;
    display: block;
}
.aui-list [class*='aui-col-xs-'] img {
    max-width: 100%;
    width: 100%;
    display: block;
}
.aui-list-item-middle .aui-list-item-inner:after {
    display: block;
}
.aui-list .aui-list-item-middle > .aui-list-item-media,
.aui-list .aui-list-item-middle > .aui-list-item-inner,
.aui-list .aui-list-item-middle > * {
    -webkit-box-align: center;
    box-align: center;
    -webkit-align-items: center;
    align-items: center;
}
.aui-list .aui-list-item-center > .aui-list-item-media,
.aui-list .aui-list-item-center > .aui-list-item-inner,
.aui-list .aui-list-item-center {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
}
.aui-list .aui-list-item i.aui-iconfont {
    -webkit-align-self: center;
    align-self: center;
    font-size: 0.8rem;
}
.aui-list-item-inner.aui-list-item-arrow {
    overflow: hidden;
    padding-right: 1.5rem;
}
.aui-list-item-arrow:before {
    content: '';
    width: 0.4rem;
    height: 0.4rem;
    position: absolute;
    top: 50%;
    right: 0.75rem;
    margin-top: -0.2rem;
    background: transparent;
    border: 1px solid #dddddd;
    border-top: none;
    border-right: none;
    z-index: 2;
    -webkit-border-radius: 0;
    border-radius: 0;
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
}
.aui-list-item.aui-list-item-arrow {
    padding-right: 0.75rem;
}
.aui-list label {
    line-height: 1.3rem;
}
.aui-list.aui-form-list .aui-list-item:active {
    background-color: #ffffff;
}
.aui-list.aui-form-list .aui-list-item-inner {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    padding: 0;
}
.aui-list .aui-list-item-label,
.aui-list .aui-list-item-label-icon {
    color: #212121;
    width: 35%;
    min-width: 1.5rem;
    margin: 0;
    padding: 0;
    padding-right: 0.25rem;
    line-height: 2.2rem;
    position: relative;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
}
.aui-list .aui-list-item-label-icon {
    width: auto;
    padding-right: 0.75rem;
}
.aui-list .aui-list-item-input {
    width: 100%;
    padding: 0;
    padding-right: 0.75rem;
    -webkit-box-flex: 1;
    box-flex: 1;
    -webkit-flex-shrink: 1;
    flex-shrink: 1;
}
.aui-list.aui-select-list .aui-list-item:active {
    background-color: #ffffff;
}
.aui-list.aui-select-list .aui-list-item-inner {
    display: block;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    -webkit-align-self: stretch;
    align-self: stretch;
}
.aui-list.aui-select-list .aui-list-item-label {
    width: auto;
    min-width: 2.2rem;
    padding: 0.5rem 0;
    padding-right: 0.75rem;
}
.aui-list.aui-form-list .aui-list-item-btn {
    padding: 0.75rem 0.75rem 0.75rem 0;
}
.aui-list textarea {
    overflow: auto;
    margin: 0.5rem 0;
    height: 3rem;
    line-height: 1rem;
    resize: none;
}
.aui-list .aui-list-item-right .aui-badge,
.aui-list .aui-list-item-right .aui-dot {
    display: inherit;
}
@media screen and (-webkit-min-device-pixel-ratio: 1.5) {
    .aui-list {
        border: none;
        background-size: 100% 1px;
        background-repeat: no-repeat;
        background-position: top;
        background-image: linear-gradient(0, #dddddd, #dddddd 50%, transparent 50%);
    }
    .aui-list .aui-list-item {
        border: none;
        background-size: 100% 1px;
        background-repeat: no-repeat;
        background-position: bottom;
        background-image: linear-gradient(0, #dddddd, #dddddd 50%, transparent 50%);
        background-image: -webkit-linear-gradient(90deg, #dddddd, #dddddd 50%, transparent 50%);
    }

    .aui-list.aui-list-in .aui-list-item {
        border: none;
        background-size: 100% 1px;
        background-repeat: no-repeat;
        background-position: 0.75rem bottom;
        background-image: linear-gradient(0, #dddddd, #dddddd 50%, transparent 50%);
        background-image: -webkit-linear-gradient(90deg, #dddddd, #dddddd 50%, transparent 50%);
    }
    .aui-list.aui-list-in .aui-list-item:last-child {
        background-position: bottom;
    }
    .aui-list.aui-list-noborder,
    .aui-list.aui-list-noborder .aui-list-item:last-child {
        border: none;
        background-size: 100% 0;
        background-image: none;
    }
}
/*tab切换类*/
.aui-tab {
    position: relative;
    background-color: #ffffff;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-align-self: center;
    align-self: center;
}
.aui-tab-item {
    width: 100%;
    height: 2.2rem;
    line-height: 2.2rem;
    position: relative;
    font-size: 0.7rem;
    text-align: center;
    color: #212121;
    margin-left: -1px;
    -webkit-box-flex: 1;
    box-flex: 1;
}
.aui-tab-item.aui-active {
    color: #039be5;
    border-bottom: 2px solid #039be5;
}
/*卡片列表布局*/
.aui-card-list {
    position: relative;
    margin-bottom: 0.35rem;
    background: #ffffff;
}
.aui-card-list-header,
.aui-card-list-footer {
    position: relative;
    min-height: 2.2rem;
    padding: 0.5rem 0.75rem;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
}
.aui-card-list-header {
    font-size: 0.8rem;
    color: #212121;
}
.aui-card-list-header.aui-card-list-user {
    display: block;
}
.aui-card-list-user-avatar {
    width: 2rem;
    float: left;
    margin-right: 0.5rem;
}
.aui-card-list-user-avatar img {
    width: 100%;
    display: block;
}
.aui-card-list-user-name {
    color: #212121;
    position: relative;
    font-size: 0.7rem;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
}
.aui-card-list-user-name > *,
.aui-card-list-user-name small {
    position: relative;
}
.aui-card-list-user-name small {
    color: #757575;
}
.aui-card-list-user-info {
    color: #757575;
    font-size: 0.6rem;
}
.aui-card-list-content {
    position: relative;
}
.aui-card-list-content-padded {
    position: relative;
    padding: 0.5rem 0.75rem;
}
.aui-card-list-content,
.aui-card-list-content-padded {
    word-break: break-all;
    font-size: 0.7rem;
    color: #212121;
}
.aui-card-list-content img,
.aui-card-list-content-padded img {
    width: 100%;
    display: block;
}
.aui-card-list-footer {
    font-size: 0.7rem;
    color: #757575;
}
.aui-card-list-footer > * {
    position: relative;
}
.aui-card-list-footer.aui-text-center {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
}
.aui-card-list-footer .aui-iconfont {
    font-size: 0.9rem;
}
/*宫格布局*/
.aui-grid {
    width: 100%;
    background-color: #ffffff;
    display: table;
    table-layout: fixed;
}
.aui-grid [class*='aui-col-'] {
    display: table-cell;
    position: relative;
    text-align: center;
    vertical-align: middle;
    padding: 1rem 0;
}
.aui-grid [class*='aui-col-xs-']:active {
    background-color: #f5f5f5;
}
.aui-grid .aui-iconfont {
    position: relative;
    z-index: 20;
    top: 0;
    height: 1.4rem;
    font-size: 1.4rem;
    line-height: 1.4rem;
}
.aui-grid .aui-grid-label {
    display: block;
    font-size: 0.7rem;
    position: relative;
    margin-top: 0.25rem;
}
.aui-grid .aui-badge {
    position: absolute;
    top: 0.5rem;
    left: 60%;
    z-index: 99;
}
.aui-grid .aui-dot {
    position: absolute;
    top: 0.5rem;
    right: 20%;
    z-index: 99;
}
/*单选、多选、开关*/
.aui-radio,
.aui-checkbox {
    width: 1.2rem;
    height: 1.2rem;
    background-color: #ffffff;
    border: solid 1px #dddddd;
    -webkit-border-radius: 0.6rem;
    border-radius: 0.6rem;
    font-size: 0.8rem;
    margin: 0;
    padding: 0;
    position: relative;
    display: inline-block;
    vertical-align: top;
    cursor: default;
    -webkit-appearance: none;
    -webkit-user-select: none;
    user-select: none;
    -webkit-transition: background-color ease 0.1s;
    transition: background-color ease 0.1s;
}
.aui-checkbox {
    border-radius: 0.1rem;
}
.aui-radio:checked,
.aui-radio.aui-checked,
.aui-checkbox:checked,
.aui-checkbox.aui-checked {
    background-color: #03a9f4;
    border: solid 1px #03a9f4;
    text-align: center;
    background-clip: padding-box;
}
.aui-radio:checked:before,
.aui-radio.aui-checked:before,
.aui-checkbox:checked:before,
.aui-checkbox.aui-checked:before,
.aui-radio:checked:after,
.aui-radio.aui-checked:after,
.aui-checkbox:checked:after,
.aui-checkbox.aui-checked:after {
    content: '';
    width: 0.5rem;
    height: 0.3rem;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -0.25rem;
    margin-top: -0.25rem;
    background: transparent;
    border: 1px solid #ffffff;
    border-top: none;
    border-right: none;
    z-index: 2;
    -webkit-border-radius: 0;
    border-radius: 0;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
.aui-radio:disabled,
.aui-radio.aui-disabled,
.aui-checkbox:disabled,
.aui-checkbox.aui-disabled {
    background-color: #dddddd;
    border: solid 1px #dddddd;
}
.aui-radio:disabled:before,
.aui-radio.aui-disabled:before,
.aui-radio:disabled:after,
.aui-radio.aui-disabled:after,
.aui-checkbox:disabled:before,
.aui-checkbox.aui-disabled:before,
.aui-checkbox:disabled:after,
.aui-checkbox.aui-disabled:after {
    content: '';
    width: 0.5rem;
    height: 0.3rem;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -0.25rem;
    margin-top: -0.25rem;
    background: transparent;
    border: 1px solid #ffffff;
    border-top: none;
    border-right: none;
    z-index: 2;
    -webkit-border-radius: 0;
    border-radius: 0;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
.aui-switch {
    width: 2.3rem;
    height: 1.2rem;
    position: relative;
    vertical-align: top;
    border: 1px solid #dddddd;
    background-color: #dddddd;
    border-radius: 0.6rem;
    background-clip: content-box;
    display: inline-block;
    outline: none;
    -webkit-appearance: none;
    appearance: none;
    -webkit-user-select: none;
    user-select: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
}
.aui-switch:before {
    width: 1.1rem;
    height: 1.1rem;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0.6rem;
    background-color: #fff;
    content: '';
    -webkit-transition: left 0.2s;
    transition: left 0.2s;
}
.aui-switch:checked {
    border-color: #03a9f4;
    background-color: #03a9f4;
}
.aui-switch:checked:before {
    left: 1.1rem;
}
/*导航栏*/
.aui-bar {
    position: relative;
    top: 0;
    right: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    min-height: 2.25rem;
    font-size: 0.9rem;
    text-align: center;
    display: table;
}
.aui-bar-nav {
    top: 0;
    line-height: 2.25rem;
    background-color: #03a9f4;
    color: #ffffff;
}
.aui-title a {
    color: inherit;
}
.aui-bar-nav .aui-title {
    min-height: 2.25rem;
    position: absolute;
    margin: 0;
    text-align: center;
    white-space: nowrap;
    right: 5rem;
    left: 5rem;
    width: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    z-index: 2;
}
.aui-bar-nav a {
    color: #ffffff;
}
.aui-bar-nav .aui-iconfont {
    position: relative;
    z-index: 20;
    font-size: 0.9rem;
    color: #ffffff;
    font-weight: 400;
    line-height: 2.25rem;
}
.aui-bar-nav .aui-pull-left {
    padding: 0 0.5rem;
    font-size: 0.8rem;
    font-weight: 400;
    z-index: 2;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
}
.aui-bar-nav .aui-pull-right {
    padding: 0 0.5rem;
    font-size: 0.8rem;
    font-weight: 400;
    z-index: 2;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
}
.aui-bar-nav .aui-btn {
    position: relative;
    z-index: 20;
    height: 2.25rem;
    line-height: 2.25rem;
    padding-top: 0;
    padding-bottom: 0;
    margin: 0;
    border-radius: 0.1rem;
    border-width: 0;
    background: transparent !important;
}
.aui-bar-nav .aui-btn.aui-btn-outlined {
    position: relative;
    padding: 0 0.15rem;
    margin: 0.5rem;
    height: 1.25rem;
    line-height: 1.25rem;
    border-width: 1px;
    background: transparent !important;
    border-color: #ffffff;
}
.aui-bar-nav .aui-btn:active {
    background: none;
}
.aui-bar-nav .aui-btn .aui-iconfont {
    font-size: 0.9rem;
    line-height: 1.25rem;
    padding: 0;
    margin: 0;
    color: #ffffff;
}
.aui-bar-light {
    color: #03a9f4;
    background-color: #ffffff;
    border-bottom: 1px solid #dddddd;
}
.aui-bar-nav.aui-bar-light .aui-iconfont {
    color: #03a9f4;
}
.aui-bar-nav.aui-bar-light .aui-btn-outlined {
    border-color: #03a9f4;
}
@media screen and (-webkit-min-device-pixel-ratio: 1.5) {
    .aui-bar.aui-bar-light {
        border: none;
        background-size: 100% 1px;
        background-repeat: no-repeat;
        background-position: bottom;
        background-image: linear-gradient(0, #dddddd, #dddddd 50%, transparent 50%);
        background-image: -webkit-linear-gradient(90deg, #dddddd, #dddddd 50%, transparent 50%);
    }
}
/*底部切换栏*/
.aui-bar-tab {
    position: fixed;
    top: auto;
    bottom: 0;
    table-layout: fixed;
    background-color: #ffffff;
    color: #757575;
}
.aui-bar-tab .aui-bar-tab-item {
    display: table-cell;
    position: relative;
    width: 1%;
    height: 2.5rem;
    text-align: center;
    vertical-align: middle;
}
.aui-bar-tab .aui-active {
    color: #039be5;
}
.aui-bar-tab .aui-bar-tab-item .aui-iconfont {
    position: relative;
    z-index: 20;
    top: 0.1rem;
    height: 1.2rem;
    font-size: 1rem;
    line-height: 1rem;
}
.aui-bar-tab .aui-bar-tab-label {
    display: block;
    font-size: 0.6rem;
    position: relative;
}
.aui-bar-tab .aui-badge {
    position: absolute;
    top: 0.1rem;
    left: 55%;
    z-index: 99;
}
.aui-bar-tab .aui-dot {
    position: absolute;
    top: 0.1rem;
    right: 30%;
    z-index: 99;
}
/*按钮工具栏*/
.aui-bar-btn {
    position: relative;
    font-size: 0.7rem;
    display: table;
    white-space: nowrap;
    margin: 0 auto;
    padding: 0;
    border: none;
    width: 100%;
    min-height: 1.8rem;
}
.aui-bar-btn-item {
    display: table-cell;
    position: relative;
    width: 1%;
    line-height: 1.6rem;
    text-align: center;
    vertical-align: middle;
    border-radius: 0;
    position: relative;
    border-width: 1px;
    border-style: solid;
    border-color: #03a9f4;
    border-left-width: 0;
}
.aui-bar-btn .aui-input,
.aui-bar-btn input,
.aui-bar-btn select {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    height: 1.8rem;
}
.aui-bar-btn-sm {
    min-height: 1.3rem;
}
.aui-bar-btn.aui-bar-btn-sm .aui-input,
.aui-bar-btn.aui-bar-btn-sm input,
.aui-bar-btn.aui-bar-btn-sm select {
    height: 1.2rem;
}
.aui-bar-btn-sm .aui-bar-btn-item {
    line-height: 1.3rem;
    font-size: 0.6rem;
}
.aui-bar-btn-item.aui-active {
    background-color: #03a9f4;
    color: #ffffff;
}
.aui-bar-btn-item:first-child {
    border-left-width: 1px;
    border-top-left-radius: 0.2rem;
    border-bottom-left-radius: 0.2rem;
}
.aui-bar-btn-item:last-child {
    border-top-right-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
    border-left: 0px;
}
.aui-bar-btn.aui-bar-btn-full .aui-bar-btn-item:first-child {
    border-left-width: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.aui-bar-btn.aui-bar-btn-full .aui-bar-btn-item:last-child {
    border-right-width: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.aui-bar-btn.aui-bar-btn-round .aui-bar-btn-item:first-child {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
}
.aui-bar-btn.aui-bar-btn-round .aui-bar-btn-item:last-child {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
}
.aui-bar-nav .aui-bar-btn {
    margin-top: 0.45rem;
    margin-bottom: 0.4rem;
    min-height: 1.3rem;
}
.aui-bar-nav .aui-bar-btn-item {
    line-height: 1.3rem;
    border-color: #ffffff;
}
.aui-bar-nav .aui-bar-btn-item.aui-active {
    background-color: #ffffff;
    color: #03a9f4;
}
.aui-bar-nav.aui-bar-light .aui-bar-btn-item {
    border-color: #03a9f4;
}
.aui-bar-nav.aui-bar-light .aui-bar-btn-item.aui-active {
    background-color: #03a9f4;
    color: #ffffff;
}
.aui-bar-nav > .aui-bar-btn {
    width: 50%;
}
.aui-info {
    position: relative;
    padding: 0.5rem 0;
    font-size: 0.7rem;
    color: #757575;
    background-color: transparent;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
}
.aui-info-item {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
}
.aui-info-item > *,
.aui-info > * {
    display: inherit;
    position: relative;
}
/*进度条*/
.aui-progress {
    width: 100%;
    height: 1rem;
    border-radius: 0.2rem;
    overflow: hidden;
    background-color: #f0f0f0;
}
.aui-progress-bar {
    float: left;
    width: 0;
    height: 100%;
    font-size: 0.6rem;
    line-height: 1rem;
    color: #ffffff;
    text-align: center;
    background-color: #03a9f4;
}
.aui-progress.sm,
.aui-progress-sm {
    height: 0.5rem;
}
.aui-progress.sm,
.aui-progress-sm,
.aui-progress.sm .aui-progress-bar,
.aui-progress-sm .aui-progress-bar {
    border-radius: 1px;
}
.aui-progress.xs,
.aui-progress-xs {
    height: 0.35rem;
}
.aui-progress.xs,
.aui-progress-xs,
.aui-progress.xs .progress-bar,
.aui-progress-xs .progress-bar {
    border-radius: 1px;
}
.aui-progress.xxs,
.aui-progress-xxs {
    height: 0.15rem;
}
.aui-progress.xxs,
.aui-progress-xxs,
.aui-progress.xxs .progress-bar,
.aui-progress-xxs .progress-bar {
    border-radius: 1px;
}
/*滑块*/
.aui-range {
    position: relative;
    display: inline-block;
}
.aui-range input[type='range'] {
    height: 0.2rem;
    border: 0;
    border-radius: 2px;
    background-color: #f0f0f0;
    position: relative;
    -webkit-appearance: none !important;
}
.aui-range input[type='range']::-webkit-slider-thumb {
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    border-color: #03a9f4;
    background-color: #03a9f4;
    -webkit-appearance: none !important;
}
.aui-range .aui-range-tip {
    font-size: 1rem;
    position: absolute;
    z-index: 999;
    top: -1.5rem;
    width: 2.4rem;
    height: 1.5rem;
    line-height: 1.5rem;
    text-align: center;
    color: #666666;
    border: 1px solid #dddddd;
    border-radius: 0.3rem;
    background-color: #ffffff;
}
.aui-input-row .aui-range input[type='range'] {
    width: 90%;
    margin-left: 5%;
}
/*搜索条*/
.aui-searchbar {
    display: -webkit-box;
    -webkit-box-pack: center;
    -webkit-box-align: center;
    height: 2.2rem;
    overflow: hidden;
    background-color: #ebeced;
    color: #9e9e9e;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    margin:0px 10px;
}
.aui-searchbar.focus {
    -webkit-box-pack: start;
}
.aui-searchbar-input {
    margin: 0 0.5rem 0 0;
    background-color: #ffffff;
    border-radius: 0.25rem;
    height: 2.2rem;
    line-height: 2.2rem;
    font-size: 0.9rem;
    position: relative;
    padding-left: 0.5rem;
    display: -webkit-box;
    -webkit-box-flex: 1;
}
.aui-searchbar form {
    width: 90%;
}
.aui-searchbar-input input {
    color: #666666;
    width: 80%;
    padding: 0;
    margin: 0;
    height: 2.2rem;
    line-height: normal;
    border: 0;
    -webkit-appearance: none;
    font-size: 0.9rem;
}
.aui-searchbar input::-webkit-input-placeholder {
    color: #ccc;
}
.aui-searchbar .aui-iconfont {
    line-height: 1.4rem;
    margin-right: 0.25rem;
    color: #9e9e9e !important;
}
.aui-searchbar .aui-searchbar-btn {
    font-size: 0.7rem;
    color: #666666;
    margin-right: -2.2rem;
    width: 2.2rem;
    height: 1.4rem;
    padding-right: 0.5rem;
    line-height: 1.4rem;
    text-align: center;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
.aui-searchbar-clear-btn {
    position: absolute;
    right: 5px;
    top: 3px;
    width: 1.1rem;
    height: 1.1rem;
    background: #eeeeee;
    border-radius: 50%;
    line-height: 0.6rem;
    text-align: center;
    display: none;
}
.aui-searchbar-clear-btn .aui-iconfont {
    font-size: 0.6rem;
    margin: 0 auto;
    position: relative;
    top: -2px;
}
.aui-searchbar .aui-searchbar-btn .aui-iconfont {
    color: #666666;
}
/*信息提示条*/
.aui-tips {
    padding: 0 0.75rem;
    width: 100%;
    z-index: 99;
    height: 1.9rem;
    line-height: 1.9rem;
    position: relative;
    background-color: rgba(0, 0, 0, 0.6);
    color: #ffffff;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: center;
    align-items: center;
}
.aui-tips .aui-tips-title {
    padding: 0 0.5rem;
    font-size: 0.7rem;
    position: relative;
    max-width: 100%;
}
/*toast*/
.aui-toast {
    background: rgba(0, 0, 0, 0.7);
    text-align: center;
    border-radius: 0.25rem;
    color: #ffffff;
    position: fixed;
    z-index: 3;
    top: 45%;
    left: 50%;
    width: 7.5em;
    min-height: 6em;
    margin-left: -3.75em;
    margin-top: -4rem;
    display: none;
}
.aui-toast .aui-iconfont {
    margin-top: 0.2rem;
    display: block;
    font-size: 2.6rem;
}
.aui-toast-content {
    margin: 0 0 0.75rem;
}
.aui-toast-loading {
    background-color: #ffffff;
    border-radius: 100%;
    margin: 0.75rem 0;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    border: 2px solid #ffffff;
    border-bottom-color: transparent;
    height: 2.25rem;
    width: 2.25rem;
    background: transparent !important;
    display: inline-block;
    -webkit-animation: rotate 1s 0s linear infinite;
    animation: rotate 1s 0s linear infinite;
}
/*dialog*/
.aui-dialog {
    width: 13.5rem;
    text-align: center;
    position: fixed;
    z-index: 999;
    left: 50%;
    margin-left: -6.75rem;
    margin-top: 0;
    top: 45%;
    border-radius: 0.3rem;
    opacity: 0;
    background-color: #ffffff;
    -webkit-transform: translate3d(0, 0, 0) scale(1.2);
    transform: translate3d(0, 0, 0) scale(1.2);
    -webkit-transition-property: -webkit-transform, opacity;
    transition-property: transform, opacity;
    /*display: none;*/
}
.aui-dialog-header {
    padding: 0.5rem 0.75rem 0 0.75rem;
    text-align: center;
    font-size: 1em;
    color: #212121;
}
.aui-dialog-body {
    padding: 0.75rem;
    overflow: hidden;
    font-size: 0.875em;
    color: #757575;
}
.aui-dialog-body input {
    border: 1px solid #dddddd;
    height: 1.8rem;
    line-height: 1.8rem;
    min-height: 1.8rem;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
}
.aui-dialog-footer {
    position: relative;
    font-size: 1em;
    border-top: 1px solid #dddddd;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
}
.aui-dialog-btn {
    position: relative;
    display: block;
    width: 100%;
    padding: 0 0.25rem;
    height: 2.2rem;
    font-size: 0.8rem;
    line-height: 2.2rem;
    text-align: center;
    color: #0894ec;
    border-right: 1px solid #dddddd;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-flex: 1;
    box-flex: 1;
}
.aui-dialog-btn:last-child {
    border-right: none;
}
.aui-dialog.aui-dialog-in {
    opacity: 1;
    -webkit-transition-duration: 300ms;
    transition-duration: 300ms;
    -webkit-transform: translate3d(0, 0, 0) scale(1);
    transform: translate3d(0, 0, 0) scale(1);
}
.aui-dialog.aui-dialog-out {
    opacity: 0;
    -webkit-transition-duration: 300ms;
    transition-duration: 300ms;
    -webkit-transform: translate3d(0, 0, 0) scale(0.815);
    transform: translate3d(0, 0, 0) scale(0.815);
}
@media only screen and (-webkit-min-device-pixel-ratio: 1.5) {
    .aui-dialog-footer {
        border: none;
        background-size: 100% 1px;
        background-repeat: no-repeat;
        background-position: top;
        background-image: linear-gradient(0, #dddddd, #dddddd 50%, transparent 50%);
        background-image: -webkit-linear-gradient(90deg, #dddddd, #dddddd 50%, transparent 50%);
    }
    .aui-dialog-btn {
        border: none;
        background-image: -webkit-linear-gradient(0deg, #dddddd, #dddddd 50%, transparent 50%);
        background-image: linear-gradient(270deg, #dddddd, #dddddd 50%, transparent 50%);
        background-size: 1px 100%;
        background-repeat: no-repeat;
        background-position: right;
    }
    .aui-dialog-btn:last-child {
        border: none;
        background-size: 0 100%;
    }
    .aui-dialog-body input {
        border: none;
        background-image: -webkit-linear-gradient(270deg, #dddddd, #dddddd 50%, transparent 50%),
            -webkit-linear-gradient(180deg, #dddddd, #dddddd 50%, transparent 50%),
            -webkit-linear-gradient(90deg, #dddddd, #dddddd 50%, transparent 50%),
            -webkit-linear-gradient(0, #dddddd, #dddddd 50%, transparent 50%);
        background-image: linear-gradient(180deg, #dddddd, #dddddd 50%, transparent 50%),
            linear-gradient(270deg, #dddddd, #dddddd 50%, transparent 50%),
            linear-gradient(0deg, #dddddd, #dddddd 50%, transparent 50%),
            linear-gradient(90deg, #dddddd, #dddddd 50%, transparent 50%);
        background-size: 100% 1px, 1px 100%, 100% 1px, 1px 100%;
        background-repeat: no-repeat;
        background-position: top, right top, bottom, left top;
    }
}
/*popup*/
.aui-popup {
    padding: 0;
    margin: 0;
    background: transparent;
    position: fixed;
    height: auto;
    min-width: 7rem;
    min-height: 4.5rem;
    z-index: 999;
    opacity: 0;
    -webkit-transition-property: -webkit-transform, opacity;
    transition-property: transform, opacity;
}
.aui-popup.aui-popup-in {
    opacity: 1;
    -webkit-transition-duration: 300ms;
    transition-duration: 300ms;
}
.aui-popup.aui-popup-out {
    opacity: 0;
    -webkit-transition-duration: 300ms;
    transition-duration: 300ms;
}
.aui-popup-right {
    right: 0.75rem;
}
.aui-popup-content {
    background-color: #ffffff;
    border-radius: 0.2rem;
    overflow: hidden;
    min-height: 4.5rem;
    height: 100%;
}
.aui-popup-top,
.aui-popup-top-left,
.aui-popup-top-right {
    top: 0.45rem;
}
.aui-popup-top {
    left: 50%;
    margin-left: -3.5rem;
}
.aui-popup-top-left {
    left: 0.45rem;
}
.aui-popup-top-right {
    right: 0.45rem;
}
.aui-popup-arrow {
    position: absolute;
    width: 10px;
    height: 10px;
    -webkit-transform-origin: 50% 50% 0;
    transform-origin: 50% 50% 0;
    background-color: transparent;
    background-image: -webkit-linear-gradient(45deg, #ffffff, #ffffff 50%, transparent 50%);
    background-image: linear-gradient(45deg, #ffffff, #ffffff 50%, transparent 50%);
}
.aui-popup-top .aui-popup-arrow,
.aui-popup-top-left .aui-popup-arrow,
.aui-popup-top-right .aui-popup-arrow {
    top: 0.2rem;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
}
.aui-popup-top .aui-popup-arrow {
    left: 50%;
    margin-left: -0.25rem;
    margin-top: -0.4rem;
}
.aui-popup-top-left .aui-popup-arrow {
    left: 0.25rem;
    margin-top: -0.4rem;
}
.aui-popup-top-right .aui-popup-arrow {
    right: 0.25rem;
    margin-top: -0.4rem;
}
.aui-popup-bottom,
.aui-popup-bottom-left,
.aui-popup-bottom-right {
    bottom: 0.45rem;
}
.aui-popup-bottom {
    left: 50%;
    margin-left: -3.5rem;
}
.aui-popup-bottom-left {
    left: 0.45rem;
}
.aui-popup-bottom-right {
    right: 0.45rem;
}
.aui-popup-bottom .aui-popup-arrow,
.aui-popup-bottom-left .aui-popup-arrow,
.aui-popup-bottom-right .aui-popup-arrow {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    bottom: 0.2rem;
}
.aui-popup-bottom .aui-popup-arrow {
    left: 50%;
    margin-left: -0.25rem;
    margin-bottom: -0.4rem;
}
.aui-popup-bottom-left .aui-popup-arrow {
    left: 0.25rem;
    margin-bottom: -0.4rem;
}
.aui-popup-bottom-right .aui-popup-arrow {
    right: 0.25rem;
    margin-bottom: -0.4rem;
}
.aui-popup .aui-list {
    background: transparent;
}
.aui-popup-content .aui-list img {
    display: block;
    width: 1rem;
}
/*actionsheet*/
.aui-actionsheet {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 0 0.5rem;
    z-index: 999;
    opacity: 0;
    -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
    transition: opacity 0.3s, transform 0.3s;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
}
.aui-actionsheet-btn {
    background-color: #ffffff;
    border-radius: 6px;
    text-align: center;
    margin-bottom: 0.5rem;
}
.aui-actionsheet-title {
    font-size: 0.6rem;
    color: #999999;
    line-height: 1.8rem;
}
.aui-actionsheet-btn-item {
    height: 2.2rem;
    line-height: 2.2rem;
    color: #0075f0;
}
/*sharebox*/
.aui-sharebox {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 999;
    background-color: #ffffff;
    opacity: 0;
    -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
    transition: opacity 0.3s, transform 0.3s;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
}
.aui-sharebox .aui-row {
    padding: 0.5rem 0;
}
.aui-sharebox img {
    display: block;
    width: 50%;
    margin: 0 auto;
}
.aui-sharebox.aui-grid [class*='aui-col-'] {
    padding: 0.5rem 0;
}
.aui-sharebox.aui-grid .aui-grid-label {
    font-size: 0.6rem;
    color: #757575;
}
.aui-sharebox-close-btn {
    width: 100%;
    height: 2.2rem;
    line-height: 2.2rem;
    color: #757575;
    text-align: center;
    font-size: 0.7rem;
}
/*折叠菜单*/
.aui-collapse-header.aui-active {
    background: #ececec;
}
.aui-collapse .aui-list-item:active {
    background: #ececec;
}
.aui-collapse-content {
    display: none;
}
.aui-collapse-content .aui-list-item:last-child {
    border-bottom: 0;
}
@media screen and (-webkit-min-device-pixel-ratio: 1.5) {
    .aui-collapse-content .aui-list-item:last-child {
        background-position: bottom;
    }
    .aui-list.aui-collapse.aui-list-noborder,
    .aui-list.aui-collapse.aui-list-noborder
        .aui-collapse-content:last-child
        .aui-list-item:last-child {
        border: none;
        background-size: 100% 1px;
        background-repeat: no-repeat;
        background-position: 0 bottom;
        background-image: linear-gradient(0, #dddddd, #dddddd 50%, transparent 50%);
        background-image: -webkit-linear-gradient(90deg, #dddddd, #dddddd 50%, transparent 50%);
    }
    .aui-list.aui-collapse.aui-list-noborder,
    .aui-list.aui-collapse.aui-list-noborder
        .aui-collapse-item:last-child
        .aui-list-item:last-child {
        border: none;
        background-size: 100% 0;
        background-image: none;
    }
    .aui-list.aui-collapse.aui-list-noborder
        .aui-collapse-item:last-child
        .aui-list-item.aui-collapse-header,
    .aui-list.aui-collapse.aui-list-noborder .aui-collapse-content .aui-list-item:last-child {
        border: none;
        background-size: 100% 0;
        background-image: none;
    }
}
.aui-collapse-header.aui-active .aui-collapse-arrow {
    display: block;
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
}
/*聊天气泡*/
.aui-chat {
    width: 100%;
    height: 100%;
    padding: 0.5rem;
}
.aui-chat .aui-chat-item {
    position: relative;
    width: 100%;
    margin-bottom: 0.75rem;
    overflow: hidden;
    display: block;
}
.aui-chat .aui-chat-header {
    width: 100%;
    text-align: center;
    margin-bottom: 0.75rem;
    font-size: 0.6rem;
    color: #757575;
}
.aui-chat .aui-chat-left {
    float: left;
}
.aui-chat .aui-chat-right {
    float: right;
}
.aui-chat .aui-chat-media {
    display: inline-block;
    max-width: 2rem;
}
.aui-chat .aui-chat-media img {
    width: 100%;
    border-radius: 50%;
}
.aui-chat .aui-chat-inner {
    position: relative;
    overflow: hidden;
    display: inherit;
}
.aui-chat .aui-chat-arrow {
    content: '';
    position: absolute;
    width: 0.6rem;
    height: 0.6rem;
    top: 0.2rem;
    -webkit-transform-origin: 50% 50% 0;
    transform-origin: 50% 50% 0;
    background-color: transparent;
}
.aui-chat .aui-chat-left .aui-chat-arrow {
    background-image: -webkit-linear-gradient(45deg, #b3e5fc, #b3e5fc 50%, transparent 50%);
    background-image: linear-gradient(45deg, #b3e5fc, #b3e5fc 50%, transparent 50%);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    left: -0.25rem;
}
.aui-chat .aui-chat-right .aui-chat-arrow {
    background-image: -webkit-linear-gradient(45deg, #ffffff, #ffffff 50%, transparent 50%);
    background-image: linear-gradient(45deg, #ffffff, #ffffff 50%, transparent 50%);
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
    right: -0.25rem;
}
.aui-chat .aui-chat-content {
    color: #212121;
    font-size: 0.7rem;
    border-radius: 0.2rem;
    min-height: 1rem;
    position: relative;
    padding: 0rem;
    max-width: 80%;
    word-break: break-all;
    word-wrap: break-word;
}
.aui-chat .aui-chat-content img {
    max-width: 100%;
    display: block;
}
.aui-chat .aui-chat-status {
    position: relative;
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    text-align: center;
}
.aui-chat .aui-chat-name {
    width: 100%;
    position: relative;
    font-size: 0.6rem;
    color: #757575;
    margin-bottom: 0.25rem;
}
.aui-chat .aui-chat-left .aui-chat-name {
    left: 0.5rem;
}
.aui-chat .aui-chat-left .aui-chat-status {
    left: 0.5rem;
    float: left;
}
.aui-chat .aui-chat-left .aui-chat-media {
    width: 2rem;
    float: left;
}
.aui-chat .aui-chat-left .aui-chat-inner {
    max-width: 70%;
}
.aui-chat .aui-chat-left .aui-chat-content {
    background-color: #b3e5fc;
    float: left;
    left: 0.5rem;
}
.aui-chat .aui-chat-right .aui-chat-media {
    width: 2rem;
    float: right;
}
.aui-chat .aui-chat-right .aui-chat-inner {
    float: right;
    max-width: 70%;
}
.aui-chat .aui-chat-right .aui-chat-name {
    float: right;
    right: 0.5rem;
    text-align: right;
}
.aui-chat .aui-chat-right .aui-chat-content {
    background-color: #ffffff;
    right: 0.5rem;
    float: right;
}
.aui-chat .aui-chat-right .aui-chat-status {
    float: right;
    right: 0.5rem;
}
/*边框样式*/
.aui-border-l {
    border-left: 1px solid #dddddd;
}
.aui-border-r {
    border-right: 1px solid #dddddd;
}
.aui-border-t {
    border-top: 1px solid #dddddd;
}
.aui-border-b {
    border-bottom: 1px solid #dddddd;
}
.aui-border {
    border: 1px solid #dddddd;
}
@media screen and (-webkit-min-device-pixel-ratio: 1.5) {
    .aui-border-l {
        border: none;
        background-image: -webkit-linear-gradient(0deg, #dddddd, #dddddd 50%, transparent 50%);
        background-image: linear-gradient(270deg, #dddddd, #dddddd 50%, transparent 50%);
        background-size: 1px 100%;
        background-repeat: no-repeat;
        background-position: left;
    }
    .aui-border-r {
        border: none;
        background-image: -webkit-linear-gradient(0deg, #dddddd, #dddddd 50%, transparent 50%);
        background-image: linear-gradient(270deg, #dddddd, #dddddd 50%, transparent 50%);
        background-size: 1px 100%;
        background-repeat: no-repeat;
        background-position: right;
    }
    .aui-border-t {
        border: none;
        background-size: 100% 1px;
        background-repeat: no-repeat;
        background-position: top;
        background-image: linear-gradient(0, #dddddd, #dddddd 50%, transparent 50%);
        background-image: -webkit-linear-gradient(90deg, #dddddd, #dddddd 50%, transparent 50%);
    }
    .aui-border-b {
        border: none;
        background-size: 100% 1px;
        background-repeat: no-repeat;
        background-position: bottom;
        background-image: linear-gradient(0, #dddddd, #dddddd 50%, transparent 50%);
        background-image: -webkit-linear-gradient(90deg, #dddddd, #dddddd 50%, transparent 50%);
    }
    .aui-border {
        border: none;
        background-image: -webkit-linear-gradient(270deg, #dddddd, #dddddd 50%, transparent 50%),
            -webkit-linear-gradient(180deg, #dddddd, #dddddd 50%, transparent 50%),
            -webkit-linear-gradient(90deg, #dddddd, #dddddd 50%, transparent 50%),
            -webkit-linear-gradient(0, #dddddd, #dddddd 50%, transparent 50%);
        background-image: linear-gradient(180deg, #dddddd, #dddddd 50%, transparent 50%),
            linear-gradient(270deg, #dddddd, #dddddd 50%, transparent 50%),
            linear-gradient(0deg, #dddddd, #dddddd 50%, transparent 50%),
            linear-gradient(90deg, #dddddd, #dddddd 50%, transparent 50%);
        background-size: 100% 1px, 1px 100%, 100% 1px, 1px 100%;
        background-repeat: no-repeat;
        background-position: top, right top, bottom, left top;
    }
}
/*时间轴*/
.aui-timeline {
    position: relative;
    padding: 0;
    list-style: none;
}
.aui-timeline:before {
    content: '';
    position: absolute;
    top: 0;
    left: 1.85rem;
    width: 2px;
    height: 100%;
    background: #ececec;
    z-index: 0;
}
.aui-timeline .aui-timeline-item {
    position: relative;
    margin-bottom: 0.75rem;
}
.aui-timeline .aui-timeline-item-header {
    background-color: #ececec;
    padding: 0.2rem 0.5rem;
    margin: 0.75rem;
    text-align: center;
    display: inline-block;
    position: relative;
    z-index: 1;
    font-size: 0.7rem;
}
.aui-timeline .aui-timeline-item-label {
    width: 2.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    font-size: 0.7em;
    background-color: #ececec;
    position: absolute;
    text-align: center;
    left: 0.75rem;
    top: 0;
}
.aui-timeline .aui-timeline-item-label-icon {
    width: 1.5rem;
    height: 1.5rem;
    font-size: 0.7rem;
    line-height: 1.5rem;
    background-color: #ececec;
    position: absolute;
    border-radius: 50%;
    text-align: center;
    left: 1.15rem;
    top: 0;
}
.aui-timeline .aui-timeline-item-inner {
    margin-left: 3.75rem;
    margin-right: 0.75rem;
    padding: 0;
    position: relative;
}
/*基础动画类*/
@keyframes rotate {
    0% {
        -webkit-transform: rotate(0deg) scale(1);
        transform: rotate(0deg) scale(1);
    }
    50% {
        -webkit-transform: rotate(180deg) scale(1);
        transform: rotate(180deg) scale(1);
    }
    100% {
        -webkit-transform: rotate(360deg) scale(1);
        transform: rotate(360deg) scale(1);
    }
}
@-webkit-keyframes rotate {
    0% {
        -webkit-transform: rotate(0deg) scale(1);
        transform: rotate(0deg) scale(1);
    }
    50% {
        -webkit-transform: rotate(180deg) scale(1);
        transform: rotate(180deg) scale(1);
    }
    100% {
        -webkit-transform: rotate(360deg) scale(1);
        transform: rotate(360deg) scale(1);
    }
}
@keyframes bounce {
    0%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    50% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
@-webkit-keyframes bounce {
    0%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    50% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
@keyframes fadeIn {
    from {
        opacity: 0.3;
    }
    to {
        opacity: 1;
    }
}
@-webkit-keyframes fadeIn {
    from {
        opacity: 0.3;
    }
    to {
        opacity: 1;
    }
}
@font-face {
    font-family: 'aui_iconfont';
    src: url('aui-iconfont.ttf') format('truetype');
}
.aui-iconfont {
    position: relative;
    font-family: 'aui_iconfont' !important;
    font-size: 0.7rem;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.aui-icon-menu:before {
    content: '\e6eb';
}
.aui-icon-paper:before {
    content: '\e6ec';
}
.aui-icon-info:before {
    content: '\e6ed';
}
.aui-icon-question:before {
    content: '\e6ee';
}
.aui-icon-left:before {
    content: '\e6f4';
}
.aui-icon-right:before {
    content: '\e6f5';
}
.aui-icon-top:before {
    content: '\e6f6';
}
.aui-icon-down:before {
    content: '\e6f7';
}
.aui-icon-share:before {
    content: '\e700';
}
.aui-icon-comment:before {
    content: '\e701';
}
.aui-icon-edit:before {
    content: '\e6d3';
}
.aui-icon-trash:before {
    content: '\e6d4';
}
.aui-icon-recovery:before {
    content: '\e6dc';
}
.aui-icon-refresh:before {
    content: '\e6dd';
}
.aui-icon-close:before {
    content: '\e6d8';
}
.aui-icon-cart:before {
    content: '\e6df';
}
.aui-icon-star:before {
    content: '\e6e0';
}
.aui-icon-plus:before {
    content: '\e6e3';
}
.aui-icon-minus:before {
    content: '\e62d';
}
.aui-icon-correct:before {
    content: '\e6e5';
}
.aui-icon-search:before {
    content: '\e6e6';
}
.aui-icon-gear:before {
    content: '\e6e8';
}
.aui-icon-map:before {
    content: '\e6d2';
}
.aui-icon-location:before {
    content: '\e6d1';
}
.aui-icon-image:before {
    content: '\e6ce';
}
.aui-icon-phone:before {
    content: '\e6c4';
}
.aui-icon-camera:before {
    content: '\e6cd';
}
.aui-icon-video:before {
    content: '\e6cc';
}
.aui-icon-qq:before {
    content: '\e6cb';
}
.aui-icon-wechat:before {
    content: '\e6c9';
}
.aui-icon-weibo:before {
    content: '\e6c8';
}
.aui-icon-note:before {
    content: '\e6c6';
}
.aui-icon-mail:before {
    content: '\e6c5';
}
.aui-icon-wechat-circle:before {
    content: '\e6ca';
}
.aui-icon-home:before {
    content: '\e706';
}
.aui-icon-forward:before {
    content: '\e6d9';
}
.aui-icon-back:before {
    content: '\e6da';
}
.aui-icon-laud:before {
    content: '\e64b';
}
.aui-icon-lock:before {
    content: '\e6ef';
}
.aui-icon-unlock:before {
    content: '\e62f';
}
.aui-icon-like:before {
    content: '\e62b';
}
.aui-icon-my:before {
    content: '\e610';
}
.aui-icon-more:before {
    content: '\e625';
}
.aui-icon-mobile:before {
    content: '\e697';
}
.aui-icon-calendar:before {
    content: '\e68a';
}
.aui-icon-date:before {
    content: '\e68c';
}
.aui-icon-display:before {
    content: '\e612';
}
.aui-icon-hide:before {
    content: '\e624';
}
.aui-icon-pencil:before {
    content: '\e615';
}
.aui-icon-flag:before {
    content: '\e6f1';
}
.aui-icon-cert:before {
    content: '\e704';
}
